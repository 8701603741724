import { useEffect, useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import ICatDocumento from '../../Interfaces/ICatDocumento'
import { useParams, useNavigate } from 'react-router-dom'
import CatalogService from '../../Services/Catalogos.Services'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'
import Slider from 'rc-slider'
import { IconContext } from 'react-icons'
import { RxDoubleArrowRight } from 'react-icons/rx'
import MFileManagerV2 from '../../../Utils/MFileManagerV2/MFileManagerV2'

export interface IRegistroDocumentosProps {}

export default function RegistroDocumentos(props: IRegistroDocumentosProps) {
  const { id } = useParams()
  let navigate = useNavigate()
  const [User, setUser] = useState(() => {
    const stickyValue = window.localStorage.getItem('User')
    return stickyValue !== null
      ? JSON.parse(stickyValue).substring(0, 4) + '.'
      : ''
  })
  const [IDCliente, setIDCliente] = useState(0)
  const [Documentos, setDocumentos] = useState<ICatDocumento[]>([])
  const [razonSocial, setrazonSocial] = useState('')
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')
  const wrapperStyle = { width: '100%', margin: 10 }

  useEffect(() => {
    if (id) {
      if (parseInt(id) > 0) {
        CatalogService.CatDocumentosGetAll()
          .then((response) => {
            setDocumentos(response.data)
          })
          .catch((e: Error) => {
            setHeader('Error')
            setMsg('Ocurrio un error: ' + e)
            setShowMsg(true)
            return
          })
        setIDCliente(parseInt(id))
        CatalogService.FichaTecnicaGet(parseInt(id))
          .then((response) => {
            setIDCliente(parseInt(id))
            setrazonSocial(
              response.data.razonSocial ? response.data.razonSocial : ''
            )
          })
          .catch((e: Error) => {
            setHeader('Error')
            setMsg('Ocurrio un error: ' + e)
            setShowMsg(true)
            return
          })
      }
    }
  }, [id])

  return (
    <div>
      <Row>
        <Col xs={12}>
          <Card border='dark' className='shadow-lg p-3 mb-1 bg-white rounded'>
            <Card.Header as='h4'>
              Solicitud de documentos: {razonSocial}
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <Row>
                  <Col xs={3}></Col>
                  <Col xs={6}>
                    <div style={wrapperStyle}>
                      <Slider
                        onChange={(e: any) => {
                          if (parseInt(e) === 0)
                          navigate('/DatosGenerales/' + IDCliente)
                          if (parseInt(e) === 1)
                            navigate('/FichaTecnica/' + IDCliente)
                          if (parseInt(e) === 2)
                            navigate('/ReunionInterna/' + IDCliente)
                          if (parseInt(e) === 3)
                            navigate('/RegistroDocumentos/' + IDCliente)
                        }}
                        dotStyle={{ borderColor: 'orange' }}
                        handleStyle={{
                          borderColor: 'black',
                          height: 20,
                          width: 20,
                          marginLeft: 0,
                          marginTop: -9,
                          backgroundColor: 'red',
                        }}
                        railStyle={{ height: 2 }}
                        min={0}
                        max={7}
                        dots
                        defaultValue={5}
                        marks={{
                          0: 'Datos generales',
                          1: 'Fecha tecnica',
                          2: 'Reunion interna',
                          3: 'Solicitar docs',
                          4: 'Reunion cliente',
                          5: 'Registro de docs',
                          6: 'Compliance',
                          7: 'Generacion MAC',
                        }}
                        step={null}
                      />
                    </div>
                  </Col>
                  <Col xs={3} style={{ textAlign: 'right' }}>
                    <Button
                      onClick={() => {
                        navigate('/Compliance/' + IDCliente)
                      }}
                    >
                      Continue
                      <IconContext.Provider
                        value={{
                          color: 'white',
                          size: '20px',
                        }}
                      >
                        <RxDoubleArrowRight />
                      </IconContext.Provider>
                    </Button>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col></Col>
        <Col xs={10}>
          <Card border='dark' className='shadow-lg p-3 mb-5 bg-white rounded'>
            <Card.Body>
              <Card.Text>
                <Row style={{ paddingBottom: '20px' }}>
                  <Col xs={12}>
                    <span style={{ fontWeight: 'bold' }}>INSTRUCCIONES:</span>{' '}
                    De un click en el nombre del archivo para subir el documento
                  </Col>
                </Row>
                <table style={{ width: '100%' }}>
                  {Documentos
                    ? Documentos.map((d, index) => {
                        return (
                          <tr>
                            <td
                              style={{
                                fontWeight: 'bold',
                                textAlign: 'right',
                                paddingRight: '5px',
                              }}
                            >
                              {index + 1}
                            </td>
                            <td>
                              <MFileManagerV2
                                Tags={IDCliente}
                                Proceso={d.idConfigFile ? d.idConfigFile : 0}
                                Usuario={User}
                                Leyenda={d.nombre}
                                Mode={1}
                                doDelete={true}
                              />
                            </td>
                          </tr>
                        )
                      })
                    : null}
                </table>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col></Col>
      </Row>

      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
