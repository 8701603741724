import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICatTipoPedimento from '../../../Components/Cliente/Interfaces/ICatTipoPedimento'
const CatTipoPedimento: ICatTipoPedimento[] = [
  {
    id: 0,
    pedimento: '- Seleccione -',
    activo: false,
  },
]
const initialState = { CatTipoPedimento }

export const CatTipoPedimentoSlice = createSlice({
  name: 'CatTipoPedimento',
  initialState: initialState,
  reducers: {
    populateCatTipoPedimento: (state, action: PayloadAction<ICatTipoPedimento[]>) => {
      state.CatTipoPedimento = []
      state.CatTipoPedimento = [
        {
          id: 0,
          pedimento: '- Seleccione -',
          activo: false,
        },
      ]
      state.CatTipoPedimento.push(...action.payload)
    },
    addCatTipoPedimento: (state, action: PayloadAction<ICatTipoPedimento>) => {
      var Existe = state.CatTipoPedimento.find(function (item) {
        return item.id === action.payload.id
      })
      if (!Existe) state.CatTipoPedimento.push(action.payload)
    },
    updateCatTipoPedimento: (state, action: PayloadAction<ICatTipoPedimento>) => {
      const i = state.CatTipoPedimento.findIndex((_element) => _element.id === action.payload.id)
      if (i > -1) state.CatTipoPedimento[i] = action.payload
      else state.CatTipoPedimento.push(action.payload)
    },
    deleteCatTipoPedimento: (state, action: PayloadAction<number>) => {
      const newArr = state.CatTipoPedimento.filter((data) => data.id != action.payload)
      state.CatTipoPedimento = newArr
    },
  },
})

export const { addCatTipoPedimento, populateCatTipoPedimento, updateCatTipoPedimento, deleteCatTipoPedimento } =
  CatTipoPedimentoSlice.actions
export default CatTipoPedimentoSlice.reducer
