import { useCallback, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { Alert, Button, Card, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'
import { IconContext } from 'react-icons'
import { RxDoubleArrowRight } from 'react-icons/rx'
import Slider from 'rc-slider'
import ICatCliente from '../../Interfaces/ICatCliente'
import CatalogService from '../../Services/Catalogos.Services'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'
import BigCalendar from '../../../BigCalendar/BigCalendar'

export interface IReunionInternaProps {}

type ValuePiece = Date | null
type Value = ValuePiece
type IEvent = { id: number; cliente: number; start: Date; end: Date; title: string; horas: number }

export default function ReunionInterna(props: IReunionInternaProps) {
  const { id } = useParams()
  let navigate = useNavigate()
  const mCatClientes = useSelector((state: RootState) => state.CatClientes.CatClientes)
  const mCatAduanas = useSelector((state: RootState) => state.CatAduanas.CatAduana)
  const mCatTipoEmbarque = useSelector((state: RootState) => state.CatTipoEmbarque.CatTipoEmbarque)
  const mCatTipoPedimento = useSelector((state: RootState) => state.CatTipoPedimento.CatTipoPedimento)
  const mCatFormaPago = useSelector((state: RootState) => state.CatFormaPago.CatFormaPago)
  const mCatFormaFacturacion = useSelector((state: RootState) => state.CatFormaFacturacion.CatFormaFacturacion)
  const mCatContactos = useSelector((state: RootState) => state.CatContactos.CatContacto)
  const localizer = momentLocalizer(moment)
  const [IDCliente, setIDCliente] = useState(0)
  const [IDReunion, setIDReunion] = useState(0)
  const [ShowModal, setShowModal] = useState(false)
  const [ShowModalEmail, setShowModalEmail] = useState(false)
  const [Reunion, setReunion] = useState('')
  const [Start, setStart] = useState<Value>(new Date())
  const [End, setEnd] = useState(new Date())
  const [myEvents, setEvents] = useState([
    {
      id: 0,
      cliente: 0,
      start: moment('20111031', 'YYYYMMDD').fromNow(),
      end: moment('20111031', 'YYYYMMDD').fromNow(),
      title: '',
      horas: 0,
    },
  ])
  const [nombreComercial, setnombreComercial] = useState('')
  const [razonSocial, setrazonSocial] = useState('')
  const [rfc, setrfc] = useState('')
  const [descripcionMercancia, setdescripcionMercancia] = useState('')
  const [patenteAduana, setpatenteAduana] = useState(0)
  const [sAduana, setsAduana] = useState('')
  const [tipoOperacion, settipoOperacion] = useState(0)
  const [usoAlmacen, setusoAlmacen] = useState(false)
  const [piesCuadrados, setpiesCuadrados] = useState(0)
  const [diasAlmacenaje, setdiasAlmacenaje] = useState(0)
  const [tipoEmbarque, settipoEmbarque] = useState(0)
  const [sTipoEmbarque, setsTipoEmbarque] = useState('')
  const [logistica, setlogistica] = useState(false)
  const [operacionesVirtuales, setoperacionesVirtuales] = useState(false)
  const [bodegaFisica, setbodegaFisica] = useState(false)
  const [tipoPedimento, settipoPedimento] = useState(0)
  const [sTipoPedimento, setsTipoPedimento] = useState('')
  const [formaPago, setformaPago] = useState(0)
  const [sFormaPago, setsFormaPago] = useState('')
  const [formaFacturacion, setformaFacturacion] = useState(0)
  const [sFormaFacturacion, setsFormaFacturacion] = useState('')
  const [diasCredito, setdiasCredito] = useState(0)
  const [pedimentos, setpedimentos] = useState(0)
  const [guias, setguias] = useState(0)
  const [fracciones, setfracciones] = useState(0)
  const [partes, setpartes] = useState(0)
  const [vehiculos, setvehiculos] = useState(0)
  const [notas, setnotas] = useState('')
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [mensageCorreo, setmensageCorreo] = useState(
    'Se hace de su conocimiento que se ha avanzado en la negociacion con un prospecto de cliente. Por lo que se requiere de su apoyo para analizar las necesidades del mismo, asi como dar seguimiento en el proceso de ALTA. NOTIFICANDO que se tendra una reunion informativa y de gestion con el cliente para obtener lo necesario para su proceso del ALTA y preparacion de inicio de operaciones.'
  )
  const wrapperStyle = { width: '100%', margin: 10 }
  const [msgColor, setMsgColor] = useState('primary')

  const handleSelectSlot = useCallback(
    (event: IEvent) => {
      setStart(event.start)
      setEnd(event.end)
      setIDReunion(event.id)
      setShowModal(true)
    },
    [setEvents]
  )

  const handleSelectEvent = useCallback((event: IEvent) => {
    setStart(event.start)
    setReunion(event.title)
    setIDReunion(event.id)
    setShowModal(true)
  }, [])

  function addHours(date: Date, hours: number) {
    date.setHours(date.getHours() + hours)
    return date
  }

 
  const defAduana = (id: number) => {
    let x = mCatAduanas.filter((a) => {
      if (a.id === id) {
        return a
      }
    })
    setsAduana(x[0].ciudad)
  }

  const defTipoEmbarque = (id: number) => {
    let x = mCatTipoEmbarque.filter((a) => {
      if (a.id === id) {
        return a
      }
    })
    setsTipoEmbarque(x[0].embarque)
  }

  const defTipoPedimento = (id: number) => {
    let x = mCatTipoPedimento.filter((a) => {
      if (a.id === id) {
        return a
      }
    })
    setsTipoPedimento(x[0].pedimento)
  }

  const defFormaPago = (id: number) => {
    let x = mCatFormaPago.filter((a) => {
      if (a.id === id) {
        return a
      }
    })
    setsFormaPago(x[0].pago)
  }

  const defsFormaFacturacion = (id: number) => {
    let x = mCatFormaFacturacion.filter((a) => {
      if (a.id === id) {
        return a
      }
    })
    setsFormaFacturacion(x[0].facturacion)
  }

  useEffect(() => {
    if (id) {
      if (parseInt(id) > 0) {
        let Customer = mCatClientes.filter((a: ICatCliente) => {
          if (a.id === parseInt(id)) {
            return a
          }
        })
        setIDCliente(Customer[0].id)
        setnombreComercial(Customer[0].nombreComercial)
        setrfc(Customer[0].rfc)
        setrazonSocial(Customer[0].razonSocial)
        setdescripcionMercancia(Customer[0].descripcionMercancia)
        setpatenteAduana(Customer[0].patenteAduana)
        defAduana(Customer[0].patenteAduana)
        settipoOperacion(Customer[0].tipoOperacion)
        setusoAlmacen(Customer[0].usoAlmacen)
        setpiesCuadrados(Customer[0].piesCuadrados)
        setdiasAlmacenaje(Customer[0].diasAlmacenaje)
        settipoEmbarque(Customer[0].tipoEmbarque)
        defTipoEmbarque(Customer[0].tipoEmbarque)
        setlogistica(Customer[0].logistica)
        setoperacionesVirtuales(Customer[0].operacionesVirtuales)
        setbodegaFisica(Customer[0].bodegaFisica)
        settipoPedimento(Customer[0].tipoPedimento)
        defTipoPedimento(Customer[0].tipoPedimento)
        setformaPago(Customer[0].formaPago)
        defFormaPago(Customer[0].formaPago)
        setformaFacturacion(Customer[0].formaFacturacion)
        defsFormaFacturacion(Customer[0].formaFacturacion)
        setdiasCredito(Customer[0].diasCredito)
        setpedimentos(Customer[0].pedimentos)
        setguias(Customer[0].guias)
        setfracciones(Customer[0].fracciones)
        setpartes(Customer[0].partes)
        setvehiculos(Customer[0].vehiculos)
        setnotas(Customer[0].notas)
      }
    }
  }, [id])

  const sendEmail = (EventNo: number, Mensaje: string) => {
    CatalogService.CalendarioSend(EventNo, Mensaje)
      .then((response) => {
        navigate('/SolicitudDocumentos/' + IDCliente)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('No se pudo enviar el correo de la reunion a los colaboradores')
        setShowMsg(true)
        return
      })
  }

  return (
    <div>
      <Row>
        <Col xs={12}>
          <Card border="dark" className="shadow-lg p-3 mb-1 bg-white rounded">
            <Card.Header as="h4">Reunion interna: {razonSocial}</Card.Header>
            <Card.Body>
              <Card.Text>
                <Row>
                  <Col xs={3}></Col>
                  <Col xs={6}>
                    <div style={wrapperStyle}>
                      <Slider
                        onChange={(e: any) => {
                          if (parseInt(e) === 0) navigate('/DatosGenerales/' + IDCliente)
                          if (parseInt(e) === 1) navigate('/FichaTecnica/' + IDCliente)
                          if (parseInt(e) === 2) navigate('/ReunionInterna/' + IDCliente)
                          if (parseInt(e) === 3) navigate('/SolicitudDocumentos/' + IDCliente)
                        }}
                        dotStyle={{ borderColor: 'orange' }}
                        handleStyle={{
                          borderColor: 'black',
                          height: 20,
                          width: 20,
                          marginLeft: 0,
                          marginTop: -9,
                          backgroundColor: 'red',
                        }}
                        railStyle={{ height: 2 }}
                        min={0}
                        max={7}
                        dots
                        defaultValue={2}
                        marks={{
                          0: 'Datos generales',
                          1: 'Fecha tecnica',
                          2: 'Reunion interna',
                          3: 'Solicitar docs',
                          4: 'Reunion cliente',
                          5: 'Registro de docs',
                          6: 'Compliance',
                          7: 'Generacion MAC',
                        }}
                        step={null}
                      />
                    </div>
                  </Col>
                  <Col xs={3} style={{ textAlign: 'right' }}>
                    <Button
                      onClick={() => {
                        setShowModalEmail(true)
                      }}
                    >
                      Continue
                      <IconContext.Provider
                        value={{
                          color: 'white',
                          size: '20px',
                        }}
                      >
                        <RxDoubleArrowRight />
                      </IconContext.Provider>
                    </Button>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col></Col>
        <Col xs={10}>
          <BigCalendar IDMaster={IDCliente} Title={razonSocial} />
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Card border="dark" className="shadow-lg p-3 mb-2 bg-white rounded">
            <Card.Body>
              <Card.Text>
                <Row>
                  <Col xs={12}>
                    <FloatingLabel controlId="floatingTextarea2" label="Cuerpo del correo a enviarse...">
                      <Form.Control
                        as="textarea"
                        placeholder="Cuerpo del correo a enviarse..."
                        style={{ height: '80px' }}
                        value={mensageCorreo}
                        onChange={(e) => {
                          setmensageCorreo(e.target.value)
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row style={{ textAlign: 'left', paddingTop: '10px' }}>
                  <Col xs={6}>
                    <span style={{ fontWeight: 'bold' }}>Nombre comercial:</span> {nombreComercial}
                  </Col>
                  <Col xs={6}>
                    <span style={{ fontWeight: 'bold' }}>Razon social:</span> {razonSocial}
                  </Col>
                </Row>
                <Row style={{ textAlign: 'left', paddingTop: '10px' }}>
                  <Col xs={2}>
                    <span style={{ fontWeight: 'bold' }}>RFC:</span> {rfc}
                  </Col>
                  <Col XS={4}></Col>
                  <Col xs={1}>
                    <span style={{ fontWeight: 'bold' }}>Contacto(s):</span>
                  </Col>
                  <Col xs={5}>
                    <table className="striped">
                      <thead>
                        <tr>
                          <th>Nombre</th>
                          <th>Correo</th>
                          <th>Telefono</th>
                        </tr>
                      </thead>
                      {mCatContactos
                        ? mCatContactos.map((c, index) => {
                            return (
                              <tr>
                                <td>{c.nombre}</td>
                                <td>{c.correo}</td>
                                <td>{c.telefono}</td>
                              </tr>
                            )
                          })
                        : ''}
                    </table>
                  </Col>
                </Row>
                <Row style={{ textAlign: 'left' }}>
                  <Col xs={12}>
                    <span style={{ fontWeight: 'bold' }}>Tipo o descripcion de la mercancia:</span>{' '}
                    {descripcionMercancia}
                  </Col>
                </Row>
                <Row style={{ textAlign: 'left' }}>
                  <Col xs={2}>
                    <span style={{ fontWeight: 'bold' }}>Patente / Aduana:</span> {sAduana}
                  </Col>
                  <Col xs={2}>
                    <span style={{ fontWeight: 'bold' }}>Tipo operacion:</span>{' '}
                    {tipoOperacion === 1
                      ? 'Importacion'
                      : tipoOperacion === 2
                      ? 'Exportacion'
                      : 'Importacion/Exportacion'}
                  </Col>
                  <Col xs={2}>
                    <span style={{ fontWeight: 'bold' }}>Uso almacen:</span> {usoAlmacen}
                  </Col>
                  <Col xs={2}>
                    <span style={{ fontWeight: 'bold' }}>Pies cuadrados:</span> {piesCuadrados}
                  </Col>
                  <Col xs={2}>
                    <span style={{ fontWeight: 'bold' }}>Dias de almacenaje:</span> {diasAlmacenaje}
                  </Col>
                  <Col xs={2}>
                    <span style={{ fontWeight: 'bold' }}>Tipo de embarque:</span> {sTipoEmbarque}
                  </Col>
                </Row>
                <Row style={{ textAlign: 'left' }}>
                  <Col xs={2}>
                    <span style={{ fontWeight: 'bold' }}>Logistica:</span> {logistica}
                  </Col>
                  <Col xs={2}>
                    <span style={{ fontWeight: 'bold' }}>Operaciones virtuales:</span> {operacionesVirtuales}
                  </Col>
                  <Col xs={2}>
                    <span style={{ fontWeight: 'bold' }}>Bodega fisica: </span> {bodegaFisica}
                  </Col>
                  <Col xs={2}>
                    <span style={{ fontWeight: 'bold' }}>Tipo de pedimento: </span> {sTipoPedimento}
                  </Col>
                  <Col xs={2}>
                    <span style={{ fontWeight: 'bold' }}>Forma de pago: </span> {sFormaPago}
                    {formaPago}
                  </Col>
                  <Col xs={2}>
                    <span style={{ fontWeight: 'bold' }}>Forma de facturacion: </span> {sFormaFacturacion}
                  </Col>
                </Row>
                <Row style={{ textAlign: 'left' }}>
                  <Col xs={2}>
                    <span style={{ fontWeight: 'bold' }}>Dias de credito:</span> {diasCredito}
                  </Col>
                  <Col xs={2}>
                    <span style={{ fontWeight: 'bold' }}>Pedimentos:</span> {pedimentos}
                  </Col>
                  <Col xs={2}>
                    <span style={{ fontWeight: 'bold' }}>Guias:</span> {guias}
                  </Col>
                  <Col xs={2}>
                    <span style={{ fontWeight: 'bold' }}>Fracciones:</span> {fracciones}
                  </Col>
                  <Col xs={2}>
                    <span style={{ fontWeight: 'bold' }}>Partes:</span> {partes}
                  </Col>
                  <Col xs={2}>
                    <span style={{ fontWeight: 'bold' }}>Vehiculos:</span> {vehiculos}
                  </Col>
                </Row>
                <Row style={{ textAlign: 'left' }}>
                  <Col xs={12}>
                    <span style={{ fontWeight: 'bold' }}>Notas:</span> {notas}
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        show={ShowModalEmail}
        onHide={() => {
          setShowModalEmail(!ShowModalEmail)
        }}
        size={'sm'}
        dialogClassName={'modal-40w'}
      >
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <Alert variant="success">
                <Alert.Heading>¿Desea enviar el correo de la reunion?</Alert.Heading>
              </Alert>
            </Col>
          </Row>
          <Row style={{ paddingTop: '20px' }}>
            <Col xs={4}>
              <Button
                variant="secondary"
                onClick={() => {
                  navigate('/SolicitudDocumentos/' + IDCliente)
                }}
              >
                Solo continuar
              </Button>
            </Col>
            <Col xs={6}></Col>
            <Col xs={2} style={{ textAlign: 'right' }}>
              <Button
                variant="primary"
                onClick={() => {
                  sendEmail(1, mensageCorreo)
                }}
              >
                Enviar
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
