import { useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import CatalogService from '../Cliente/Services/Catalogos.Services'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { populateCatDocumentos } from '../../store/features/Catalogos/CatDocumentosSlice'
import { populateCatClientes } from '../../store/features/Catalogos/CatClientesSlice'
import { populateCatContacto } from '../../store/features/Catalogos/CatContactosSlice'
import { populateCatDepartamento } from '../../store/features/Catalogos/CatDepartamentosSlice'
import { populateCatAduana } from '../../store/features/Catalogos/CatAduanasSlice'
import { populateCatFormaFacturacion } from '../../store/features/Catalogos/CatFormaFacturacionSlice'
import { populateCatFormaPago } from '../../store/features/Catalogos/CatFormaPagoSlice'
import { populateCatTipoEmbarque } from '../../store/features/Catalogos/CatTipoEmbarqueSlice'
import { populateCatTipoPedimento } from '../../store/features/Catalogos/CatTipoPedimentoSlice'

interface IProps {}

export default function Dashboard(props: IProps) {
  const dispatch = useDispatch()
  const mCatDepartamentos = useSelector((state: RootState) => state.CatDepartamentos.CatDepartamento)
  const mCatDocumentos = useSelector((state: RootState) => state.CatDocumentos.CatDocumentos)
  const mCatClientes = useSelector((state: RootState) => state.CatClientes.CatClientes)
  const mCatContactos = useSelector((state: RootState) => state.CatContactos.CatContacto)
  const mCatAduanas = useSelector((state: RootState) => state.CatAduanas.CatAduana)
  const mCatFormaFacturacion = useSelector((state: RootState) => state.CatFormaFacturacion.CatFormaFacturacion)
  const mCatFormaPago = useSelector((state: RootState) => state.CatFormaPago.CatFormaPago)
  const mCatTipoEmbarque = useSelector((state: RootState) => state.CatTipoEmbarque.CatTipoEmbarque)
  const mCatTipoPedimento = useSelector((state: RootState) => state.CatTipoPedimento.CatTipoPedimento)

  const loadCatDocumentos = () => {
    CatalogService.CatDocumentosGetAll()
      .then((response) => {
        dispatch(populateCatDocumentos(response.data))
      })
      .catch((e: Error) => {})
  }

  const loadCatClientes = () => {
    CatalogService.CatClientesGetAll()
      .then((response) => {
        dispatch(populateCatClientes(response.data))
      })
      .catch((e: Error) => {})
  }

  const loadCatContactos = () => {
    CatalogService.CatContactosGet()
      .then((response) => {
        dispatch(populateCatContacto(response.data))
      })
      .catch((e: Error) => {})
  }

  const loadCatDepartamentos = () => {
    CatalogService.CatDepartamentosGet()
      .then((response) => {
        dispatch(populateCatDepartamento(response.data))
      })
      .catch((e: Error) => {})
  }

  const loadCatAduanas = () => {
    CatalogService.CatAduanasGet()
      .then((response) => {
        dispatch(populateCatAduana(response.data))
      })
      .catch((e: Error) => {})
  }

  const loadCatFormaFacturacion = () => {
    CatalogService.CatFormaFacturacionGet()
      .then((response) => {
        dispatch(populateCatFormaFacturacion(response.data))
      })
      .catch((e: Error) => {})
  }

  const loadCatFormaPago = () => {
    CatalogService.CatFormaPagoGet()
      .then((response) => {
        dispatch(populateCatFormaPago(response.data))
      })
      .catch((e: Error) => {})
  }

  const loadCatTipoEmbarque = () => {
    CatalogService.CatTipoEmbarqueGet()
      .then((response) => {
        dispatch(populateCatTipoEmbarque(response.data))
      })
      .catch((e: Error) => {})
  }

  const loadCatTipoPedimento = () => {
    CatalogService.CatTipoPedimentoGet()
      .then((response) => {
        dispatch(populateCatTipoPedimento(response.data))
      })
      .catch((e: Error) => {})
  }

  useEffect(() => {
    console.log(mCatClientes)
    if (mCatDocumentos.length == 1) loadCatDocumentos()
    if (mCatClientes.length == 1) loadCatClientes()
    if (mCatContactos.length == 1) loadCatContactos()
    if (mCatDepartamentos.length == 1) loadCatDepartamentos()
    if (mCatAduanas.length == 1) loadCatAduanas()
    if (mCatFormaFacturacion.length == 1) loadCatFormaFacturacion()
    if (mCatFormaPago.length == 1) loadCatFormaPago()
    if (mCatTipoEmbarque.length == 1) loadCatTipoEmbarque()
    if (mCatTipoPedimento.length == 1) loadCatTipoPedimento()
  }, [])

  return (
    <div>
      <br />
      <Container>
        <Row xs={1} md={3} className="g-4"></Row>
      </Container>
    </div>
  )
}
