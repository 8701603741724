import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICatDocumentos from '../../../Components/Cliente/Interfaces/ICatDocumento'
const CatDocumentos: ICatDocumentos[] = [
  { id: 0, nombre: '- Seleccione destino -', impo:0, expo:0, idConfigFile:0, activo: false },
]
const initialState = { CatDocumentos }

export const CatDocumentosSlice = createSlice({
  name: 'CatDocumentos',
  initialState: initialState,
  reducers: {
    populateCatDocumentos: (state, action: PayloadAction<ICatDocumentos[]>) => {
      state.CatDocumentos = []
      state.CatDocumentos.push(...action.payload)
    },
    addCatDocumentos: (state, action: PayloadAction<ICatDocumentos>) => {
      var Existe = state.CatDocumentos.find(function (item) {
        return item.id === action.payload.id
      })
      if (!Existe) state.CatDocumentos.push(action.payload)
    },
    updateCatDocumentos: (state, action: PayloadAction<ICatDocumentos>) => {
      const i = state.CatDocumentos.findIndex(
        (_element) => _element.id === action.payload.id
      )
      if (i > -1) state.CatDocumentos[i] = action.payload
      else state.CatDocumentos.push(action.payload)
    },
    deleteCatDocumentos: (state, action: PayloadAction<number>) => {
      const newArr = state.CatDocumentos.filter(
        (data) => data.id != action.payload
      )
      state.CatDocumentos = newArr
    },
  },
})

export const {
  addCatDocumentos,
  populateCatDocumentos,
  updateCatDocumentos,
  deleteCatDocumentos,
} = CatDocumentosSlice.actions
export default CatDocumentosSlice.reducer
