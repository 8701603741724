import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICatAduana from '../../../Components/Cliente/Interfaces/ICatAduana'
const CatAduana: ICatAduana[] = [
  {
    id: 0,
    ciudad: '- Seleccione -',
    numero: '0',
    local: false
  },
]
const initialState = { CatAduana }

export const CatAduanaSlice = createSlice({
  name: 'CatAduana',
  initialState: initialState,
  reducers: {
    populateCatAduana: (state, action: PayloadAction<ICatAduana[]>) => {
      state.CatAduana = []
      state.CatAduana.push(...action.payload)
    },
    addCatAduana: (state, action: PayloadAction<ICatAduana>) => {
      var Existe = state.CatAduana.find(function (item) {
        return item.id === action.payload.id
      })
      if (!Existe) state.CatAduana.push(action.payload)
    },
    updateCatAduana: (state, action: PayloadAction<ICatAduana>) => {
      const i = state.CatAduana.findIndex((_element) => _element.id === action.payload.id)
      if (i > -1) state.CatAduana[i] = action.payload
      else state.CatAduana.push(action.payload)
    },
    deleteCatAduana: (state, action: PayloadAction<number>) => {
      const newArr = state.CatAduana.filter((data) => data.id != action.payload)
      state.CatAduana = newArr
    },
  },
})

export const { addCatAduana, populateCatAduana, updateCatAduana, deleteCatAduana } = CatAduanaSlice.actions
export default CatAduanaSlice.reducer
