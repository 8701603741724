import { useEffect, useState } from 'react'
import { Alert, Button, Card, Col, FloatingLabel, Form, Modal, Row } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'
import CatalogService from '../../Services/Catalogos.Services'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'
import Slider from 'rc-slider'
import { IconContext } from 'react-icons'
import { RxDoubleArrowRight } from 'react-icons/rx'
import DTODocumento from '../../DTO/DTODocumento'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import '../../../../css/Cliente.css'
import BigCalendar from '../../../BigCalendar/BigCalendar'
export interface IReunionClienteProps {}

export default function ReunionCliente(props: IReunionClienteProps) {
  const { id } = useParams()
  let navigate = useNavigate()
  const mCatContactos = useSelector((state: RootState) => state.CatContactos.CatContacto)
  /*   const [User, setUser] = useState(() => {
    const stickyValue = window.localStorage.getItem('User')
    return stickyValue !== null ? JSON.parse(stickyValue).substring(0, 4) + '.' : ''
  }) */
  const [IDCliente, setIDCliente] = useState(0)
  const [Documentos, setDocumentos] = useState<DTODocumento[]>([])
  const [razonSocial, setrazonSocial] = useState('')
  const [mensageCorreo, setmensageCorreo] = useState(
    `Hemos iniciado el proceso para darlo de alta en nuestra base de datos y sistemas, para que de esta manera podamos ofrecerle el mejor de los servicios.
Por lo cual, nuestro equipo de especialistas ya ha analizado y revisado el servicio que le proporcionaremos, por lo que nos sera necesario el que nos comparta la siguiente información.
De tener alguna duda o comentario, favor de hacérnoslo saber y estaremos gustosos de atenderle y apoyarle en lo que sea necesario.
Agradecemos de antemano su apoyo para poder culminar el proceso de registro de alta de cliente lo mas pronto posible.`
  )
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')
  const [ShowModalEmail, setShowModalEmail] = useState(false)
  const wrapperStyle = { width: '100%', margin: 10 }

  const loadDocs = (Cliente: number) => {
    CatalogService.CatClientesDocumentosGet(Cliente)
      .then((response) => {
        setDocumentos(response.data.filter((d) => d.activo === true))
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    if (id) {
      if (parseInt(id) > 0) {
        setIDCliente(parseInt(id))
        loadDocs(parseInt(id))
        CatalogService.FichaTecnicaGet(parseInt(id))
          .then((response) => {
            setIDCliente(parseInt(id))
            setrazonSocial(response.data.razonSocial ? response.data.razonSocial : '')
          })
          .catch((e: Error) => {
            setHeader('Error')
            setMsg('Ocurrio un error: ' + e)
            setShowMsg(true)
            return
          })
      }
    }
  }, [id])

  /* const toggleDoc = (doc: DTODocumento) => {
    CatalogService.CatClientesDocumentoToggle(doc.id)
      .then((response) => {
        loadDocs(IDCliente)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  } */

  const sendEmail = (EventNo: number, Mensaje: string) => {
    CatalogService.CalendarioSend(EventNo, Mensaje)
      .then((response) => {
        navigate('/RegistroDocumentos/' + IDCliente)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('No se pudo enviar el correo de la reunion a los colaboradores')
        setShowMsg(true)
        return
      })
  }

  return (
    <div>
      <Row>
        <Col xs={12}>
          <Card border="dark" className="shadow-lg p-3 mb-1 bg-white rounded">
            <Card.Header as="h4">Reunion con el cliente: {razonSocial}</Card.Header>
            <Card.Body>
              <Card.Text>
                <Row>
                  <Col xs={3}></Col>
                  <Col xs={6}>
                    <div style={wrapperStyle}>
                      <Slider
                        onChange={(e: any) => {
                          if (parseInt(e) === 0) navigate('/DatosGenerales/' + IDCliente)
                          if (parseInt(e) === 1) navigate('/FichaTecnica/' + IDCliente)
                          if (parseInt(e) === 2) navigate('/ReunionInterna/' + IDCliente)
                          if (parseInt(e) === 3) navigate('/SolicitarDocumentos/' + IDCliente)
                          if (parseInt(e) === 4) navigate('/ReunionCliente/' + IDCliente)
                        }}
                        dotStyle={{ borderColor: 'orange' }}
                        handleStyle={{
                          borderColor: 'black',
                          height: 20,
                          width: 20,
                          marginLeft: 0,
                          marginTop: -9,
                          backgroundColor: 'red',
                        }}
                        railStyle={{ height: 2 }}
                        min={0}
                        max={7}
                        dots
                        defaultValue={4}
                        marks={{
                          0: 'Datos generales',
                          1: 'Fecha tecnica',
                          2: 'Reunion interna',
                          3: 'Solicitar docs',
                          4: 'Reunion cliente',
                          5: 'Registro de docs',
                          6: 'Compliance',
                          7: 'Generacion MAC',
                        }}
                        step={null}
                      />
                    </div>
                  </Col>
                  <Col xs={3} style={{ textAlign: 'right' }}>
                    <Button
                      onClick={() => {
                        setShowModalEmail(true)
                      }}
                    >
                      Continue
                      <IconContext.Provider
                        value={{
                          color: 'white',
                          size: '20px',
                        }}
                      >
                        <RxDoubleArrowRight />
                      </IconContext.Provider>
                    </Button>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col></Col>
        <Col xs={10}>
          <BigCalendar IDMaster={IDCliente} Title={razonSocial} />
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <Col></Col>
        <Col xs={10}>
          <Card border="dark" className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <Card.Text>
                <Row>
                  <Col xs={12}>
                    <Card border="dark" style={{ textAlign: 'left' }}>
                      <Card.Body>
                        <Card.Text>
                          <Row>
                            <Col>
                              <span style={{ fontWeight: 'bold' }}>Para:</span> &nbsp;
                              {mCatContactos
                                ? mCatContactos.map((d) => {
                                    return d.correo+', '
                                  })
                                : null}
                            </Col>
                          </Row>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row style={{paddingTop:'10px'}}>
                  <Col xs={12}>
                    <FloatingLabel controlId="floatingTextarea2" label="Cuerpo del correo a enviarse...">
                      <Form.Control
                        as="textarea"
                        placeholder="Cuerpo del correo a enviarse..."
                        style={{ height: '140px' }}
                        value={mensageCorreo}
                        onChange={(e) => {
                          setmensageCorreo(e.target.value)
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col>&nbsp;</Col>
                </Row>
                <table style={{ width: '100%' }}>
                  <tr>
                    <th colSpan={2} style={{ textAlign: 'center', fontSize: '18px' }}>
                      Lista de documentos necesarios
                    </th>
                  </tr>
                  {Documentos
                    ? Documentos.map((d, index) => {
                        return (
                          <tr>
                            <td
                              style={{
                                fontWeight: 'bold',
                                textAlign: 'right',
                                paddingRight: '5px',
                              }}
                            >
                              {index + 1}
                            </td>
                            <td style={{ paddingLeft: '10px' }}>
                              <span className={d.activo ? '' : 'lineThrough__none'}>{d.nombre}</span>
                            </td>
                          </tr>
                        )
                      })
                    : null}
                </table>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col></Col>
      </Row>
      <Modal
        show={ShowModalEmail}
        onHide={() => {
          setShowModalEmail(!ShowModalEmail)
        }}
        size={'sm'}
        dialogClassName={'modal-40w'}
      >
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <Alert variant="success">
                <Alert.Heading>¿Desea enviar el correo de la reunion?</Alert.Heading>
              </Alert>
            </Col>
          </Row>
          <Row style={{ paddingTop: '20px' }}>
            <Col xs={4}>
              <Button
                variant="secondary"
                onClick={() => {
                  navigate('/RegistroDocumentos/' + IDCliente)
                }}
              >
                Solo continuar
              </Button>
            </Col>
            <Col xs={6}></Col>
            <Col xs={2} style={{ textAlign: 'right' }}>
              <Button
                variant="primary"
                onClick={() => {
                  sendEmail(2, mensageCorreo)
                }}
              >
                Enviar
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
