import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import CatalogService from '../Cliente/Services/Catalogos.Services'
import DateTimePicker from 'react-datetime-picker'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'
import ICalendario from '../Cliente/Interfaces/ICalendario'

export interface IBigCalendarProps {
    IDMaster: number,
    Title: string
}

type ValuePiece = Date | null
type Value = ValuePiece
type IEvent = { id: number; cliente: number; start: Date; end: Date; title: string; horas: number }

export default function BigCalendar(props: IBigCalendarProps) {
  const localizer = momentLocalizer(moment)
  const [IDReunion, setIDReunion] = useState(0)
  const [Start, setStart] = useState<Value>(new Date())
  const [End, setEnd] = useState(new Date())
  const [ShowModal, setShowModal] = useState(false)
  const [Reunion, setReunion] = useState('')
  const [Horas, setHoras] = useState(1)
  const [myEvents, setEvents] = useState([
    {
      id: 0,
      cliente: 0,
      start: moment('20111031', 'YYYYMMDD').fromNow(),
      end: moment('20111031', 'YYYYMMDD').fromNow(),
      title: '',
      horas: 0,
    },
  ])

  const handleSelectSlot = useCallback(
    (event: IEvent) => {
      setStart(event.start)
      setEnd(event.end)
      setIDReunion(event.id)
      setShowModal(true)
    },
    [setEvents]
  )

  const handleSelectEvent = useCallback((event: IEvent) => {
    setStart(event.start)
    setReunion(event.title)
    setIDReunion(event.id)
    setShowModal(true)
  }, [])

  const saveEvent = () => {
    let Inicio = Start ? Start : new Date()
    const Fin = End ? addHours(End, -26) : new Date(Inicio.getTime() + 2 * 60 * 1000)
    const dataEvent: IEvent = {
      id: 0,
      cliente: props.IDMaster,
      start: Inicio,
      end: Fin,
      title: props.Title + ' : ' + Reunion,
      horas: Horas,
    }
    Inicio = addHours(Inicio, -5)
    const data: ICalendario = {
      id: 0,
      cliente: props.IDMaster,
      inicio: Inicio.toISOString(),
      fin: Fin.toISOString(),
      tema: props.Title + ' : ' + Reunion,
      horas: Horas,
    }
    console.log('data del evento:' + JSON.stringify(data))
    CatalogService.CalendarioAppend(data)
      .then((response) => {
        const dataEvent: IEvent = {
          id: response.data.id,
          cliente: props.IDMaster,
          start: addHours(Inicio, 5),
          end: addHours(Fin, 5),
          title: props.Title + ' : ' + Reunion,
          horas: Horas,
        }
        setEvents((prev: any) => [...prev, dataEvent])
      })
      .catch((e: Error) => {})
  }

  const deleteEvent = () => {
    CatalogService.CalendarioDelete(IDReunion)
      .then((response) => {
        let x = myEvents.filter((a) => {
          if (a.title != Reunion) {
            return a
          }
        })
        setEvents(x)
      })
      .catch((e: Error) => {})
  }

  function addHours(date: Date, hours: number) {
    date.setHours(date.getHours() + hours)
    return date
  }

  const loadEvents = () => {
    CatalogService.CalendarioGet()
      .then((response) => {
        response.data.map(function (event, index, array) {
          let Inicio = addHours(new Date(event.inicio), -5).toISOString().replace(/\D/g, '').substring(0, 12)
          let Fin = addHours(new Date(event.fin), -5).toISOString().replace(/\D/g, '').substring(0, 12)
          const data = {
            id: event.id,
            start: moment(Inicio, 'YYYYMMDDHHmm'),
            end: moment(Fin, 'YYYYMMDDHHmm'),
            title: event.tema,
          }
          console.log(data)
          setEvents((prev: any) => [...prev, data])
        })
      })
      .catch((e: Error) => {})
  }

  useEffect(() => {
    loadEvents()
  }, [])
  
  return (
    <div>
      <Card border="dark" className="shadow-lg p-3 mb-1 bg-white rounded">
        <Card.Body>
          <Card.Text>
            <Calendar
              localizer={localizer}
              defaultDate={new Date()}
              events={myEvents}
              onSelectEvent={handleSelectEvent}
              onSelectSlot={handleSelectSlot}
              selectable
              style={{ height: 300 }}
            />
          </Card.Text>
        </Card.Body>
      </Card>
      <Modal
        show={ShowModal}
        onHide={() => {
          setShowModal(!ShowModal)
        }}
        size={'sm'}
        dialogClassName={'modal-40w'}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">Reunion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={3}>Fecha y hora</Col>
            <Col xs={6}>
              <DateTimePicker onChange={setStart} value={Start} />
            </Col>
            <Col xs={1}>Horas</Col>
            <Col xs={2}>
              <Form.Select onChange={(e) => setHoras(parseInt(e.target.value))}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </Form.Select>
            </Col>
          </Row>
          <Row style={{ paddingTop: '20px' }}>
            <Col xs={1}>Tema</Col>
            <Col xs={11}>
              <Form.Control
                as="textarea"
                aria-label="With textarea"
                rows={3}
                value={Reunion}
                size="sm"
                onChange={(e) => {
                  setReunion(e.target.value)
                }}
              />
            </Col>
          </Row>
          <Row style={{ paddingTop: '20px' }}>
            <Col xs={2}>
              <Button
                variant="danger"
                onClick={() => {
                  deleteEvent()
                }}
              >
                Elimina
              </Button>
            </Col>
            <Col xs={8}></Col>
            <Col xs={2}>
              <Button
                variant="primary"
                onClick={() => {
                  saveEvent()
                }}
              >
                Guarda
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  )
}
