import { Button, Card, Col, FloatingLabel, Row, Form } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'
import { RootState } from '../../../../store/store'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import Slider from 'rc-slider'
import CatalogService from '../../Services/Catalogos.Services'
import { IconContext } from 'react-icons'
import { RxDoubleArrowRight } from 'react-icons/rx'
import { BsFillEraserFill } from 'react-icons/bs'
import { FaSave } from 'react-icons/fa'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'
import MFileManagerV2 from '../../../Utils/MFileManagerV2/MFileManagerV2'
import DTOFichaTecnica from '../../DTO/DTOFichaTecnica'
import Select from 'react-select'
import 'rc-slider/assets/index.css'
import DTOAduanas from '../../DTO/DTOAduanas'

export interface IFichaTecnicaProps {}

/* type DTOAduanas = {
  value: string
  label: string
} */

export default function FichaTecnica(props: IFichaTecnicaProps) {
  let navigate = useNavigate()
  const mCatAduanas = useSelector((state: RootState) => state.CatAduanas.CatAduana)
  const mCatFormaFacturacion = useSelector((state: RootState) => state.CatFormaFacturacion.CatFormaFacturacion)
  const mCatFormaPago = useSelector((state: RootState) => state.CatFormaPago.CatFormaPago)
  const mCatTipoEmbarque = useSelector((state: RootState) => state.CatTipoEmbarque.CatTipoEmbarque)
  const mCatTipoPedimento = useSelector((state: RootState) => state.CatTipoPedimento.CatTipoPedimento)
  const [User, setUser] = useState(() => {
    const stickyValue = window.localStorage.getItem('User')
    return stickyValue !== null ? JSON.parse(stickyValue).substring(0, 4) + '.' : ''
  })
  const { id } = useParams()
  const [IDCliente, setIDCliente] = useState(0)
  const [razonSocial, setrazonSocial] = useState('')
  const [descripcionMercancia, setdescripcionMercancia] = useState('')
  const [patenteAduana, setpatenteAduana] = useState(0)
  const [tipoOperacion, settipoOperacion] = useState(0)
  const [usoAlmacen, setusoAlmacen] = useState(false)
  const [piesCuadrados, setpiesCuadrados] = useState(0)
  const [diasAlmacenaje, setdiasAlmacenaje] = useState(0)
  const [tipoEmbarque, settipoEmbarque] = useState(0)
  const [logistica, setlogistica] = useState(false)
  const [distribucion, setdistribucion] = useState(false)
  const [operacionesVirtuales, setoperacionesVirtuales] = useState(false)
  const [bodegaFisica, setbodegaFisica] = useState(false)
  const [tipoPedimento, settipoPedimento] = useState(0)
  const [formaPago, setformaPago] = useState(0)
  const [formaFacturacion, setformaFacturacion] = useState(0)
  const [diasCredito, setdiasCredito] = useState(0)
  const [pedimentos, setpedimentos] = useState(0)
  const [guias, setguias] = useState(0)
  const [fracciones, setfracciones] = useState(0)
  const [partes, setpartes] = useState(0)
  const [vehiculos, setvehiculos] = useState(0)
  const [notas, setnotas] = useState('')
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')
  const wrapperStyle = { width: '100%', margin: 10 }
  const [otrasAduanas, setotrasAduanas] = useState<DTOAduanas[] | null>(null)
  const [Aduanas, setAduanas] = useState<DTOAduanas[]>([])
  const [OtrasAduanas, setOtrasAduanas] = useState<DTOAduanas[]>([])

  const saveData = () => {
    console.log('otras aduanas = '+OtrasAduanas)
    if (patenteAduana === 0) {
      setHeader('Error')
      setMsg('Para poder guardar la informacion, primero de seleccionar la Aduana')
      setShowMsg(true)
      return
    }
    if (tipoOperacion === 0) {
      setHeader('Error')
      setMsg('Para poder guardar la informacion, primero de seleccionar el Tipo de Operacion')
      setShowMsg(true)
      return
    }
    if (tipoEmbarque === 0) {
      setHeader('Error')
      setMsg('Para poder guardar la informacion, primero de seleccionar el tipo de embarque')
      setShowMsg(true)
      return
    }
    if (tipoPedimento === 0) {
      setHeader('Error')
      setMsg('Para poder guardar la informacion, primero de seleccionar el tipo de pedimento')
      setShowMsg(true)
      return
    }
    if (formaPago === 0) {
      setHeader('Error')
      setMsg('Para poder guardar la informacion, primero de seleccionar la forma de pago')
      setShowMsg(true)
      return
    }
    if (formaFacturacion === 0) {
      setHeader('Error')
      setMsg('Para poder guardar la informacion, primero de seleccionar la forma de facturacion')
      setShowMsg(true)
      return
    }
    if (notas.length === 0) {
      setHeader('Error')
      setMsg('Para poder guardar la informacion, primero de proporcionar sus notas')
      setShowMsg(true)
      return
    }
    const data: DTOFichaTecnica = {
      id: IDCliente,
      descripcionMercancia,
      patenteAduana,
      tipoOperacion,
      usoAlmacen,
      piesCuadrados,
      diasAlmacenaje,
      tipoEmbarque,
      logistica,
     /*  operacionesVirtuales, */
     /*  bodegaFisica, */
      tipoPedimento,
      formaPago,
      formaFacturacion,
     /*  diasCredito, */
      pedimentos,
      guias,
      fracciones,
      partes,
      vehiculos,
      notas,
      otrasAduanas: OtrasAduanas ? OtrasAduanas : []
    }
    console.log(data)
    CatalogService.FichaTecnicaAppend(data)
      .then((response) => {
        setHeader('Confirmacion')
        setMsg('Los cambios se han guardado exitosamente.')
        setShowMsg(true)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    if (id) {
      if (parseInt(id) > 0) {
        setIDCliente(parseInt(id))
        CatalogService.FichaTecnicaGet(parseInt(id))
          .then((response) => {
            setrazonSocial(response.data.razonSocial ? response.data.razonSocial : '')
            setdescripcionMercancia(response.data.descripcionMercancia)
            setpatenteAduana(response.data.patenteAduana)
            settipoOperacion(response.data.tipoOperacion)
            setusoAlmacen(response.data.usoAlmacen)
            setpiesCuadrados(response.data.piesCuadrados)
            setdiasAlmacenaje(response.data.diasAlmacenaje)
            settipoEmbarque(response.data.tipoEmbarque)
            setlogistica(response.data.logistica)
           /*  setoperacionesVirtuales(response.data.operacionesVirtuales) */
           /*  setbodegaFisica(response.data.bodegaFisica) */
            settipoPedimento(response.data.tipoPedimento)
            setformaPago(response.data.formaPago)
            setformaFacturacion(response.data.formaFacturacion)
           /*  setdiasCredito(response.data.diasCredito) */
            setpedimentos(response.data.pedimentos)
            setguias(response.data.guias)
            setfracciones(response.data.fracciones)
            setpartes(response.data.partes)
            setvehiculos(response.data.vehiculos)
            setnotas(response.data.notas)
          })
          .catch((e: Error) => {
            setHeader('Error')
            setMsg('Ocurrio un error: ' + e)
            setShowMsg(true)
            return
          })
        //        setIDCliente(parseInt(id))
      }
    }
  }, [id])

  useEffect(() => {
    const aduanas = mCatAduanas
      .filter((d) => {
        return !d.local && d.numero !== '0'
      })
      .map(({ numero, ciudad }) => ({ value: numero.toString(), label: ciudad }))
    setAduanas(aduanas)
  }, [])

  useEffect(() => {
    console.log(otrasAduanas)
  }, [otrasAduanas])

  const avanzaProceso = () => {
    console.log(IDCliente)
    CatalogService.CatClientesValidaProceso(IDCliente, 2)
      .then((response) => {
        console.log(response.data)
        if (response.data) navigate('/ReunionInterna/' + IDCliente)
        else {
          setHeader('Error')
          setMsg('Para poder continuar es necesario que la informacion de todos los campos este llena')
          setShowMsg(true)
          return
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Card border="dark" className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Header as="h4">Ficha tecnica: {razonSocial}</Card.Header>
            <Card.Body>
              <Card.Text>
                <Row>
                  <Col xs={3}></Col>
                  <Col xs={6}>
                    <div style={wrapperStyle}>
                      <Slider
                        onChange={(e: any) => {
                          if (parseInt(e) === 0) navigate('/DatosGenerales/' + IDCliente)
                          if (parseInt(e) === 1) navigate('/FichaTecnica/' + IDCliente)
                          if (parseInt(e) === 2) navigate('/ReunionInterna/' + IDCliente)
                          if (parseInt(e) === 3) navigate('/SolicitudDocumentos/' + IDCliente)
                        }}
                        dotStyle={{ borderColor: 'orange' }}
                        handleStyle={{
                          borderColor: 'black',
                          height: 20,
                          width: 20,
                          marginLeft: 0,
                          marginTop: -9,
                          backgroundColor: 'red',
                        }}
                        railStyle={{ height: 2 }}
                        min={0}
                        max={7}
                        dots
                        defaultValue={1}
                        marks={{
                          0: 'Datos generales',
                          1: 'Fecha tecnica',
                          2: 'Reunion interna',
                          3: 'Solicitar docs',
                          4: 'Reunion cliente',
                          5: 'Registro de docs',
                          6: 'Compliance',
                          7: 'Generacion MAC',
                        }}
                        step={null}
                      />
                    </div>
                  </Col>
                  <Col xs={3} style={{ textAlign: 'right' }}>
                    <Button
                      onClick={() => {
                        avanzaProceso()
                      }}
                    >
                      Continue
                      <IconContext.Provider
                        value={{
                          color: 'white',
                          size: '20px',
                        }}
                      >
                        <RxDoubleArrowRight />
                      </IconContext.Provider>
                    </Button>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '30px' }}>
                  <Col xs={11}>
                    <FloatingLabel
                      controlId="floatingTextarea"
                      label="Tipo o descripcion de la mercancia"
                      className="mb-3"
                    >
                      <Form.Control
                        style={{ height: '100px' }}
                        as="textarea"
                        placeholder="Tipo o descripcion de la mercancia"
                        value={descripcionMercancia}
                        size="sm"
                        onChange={(e) => {
                          setdescripcionMercancia(e.target.value)
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={1}>
                    <Row style={{ paddingTop: '15px' }}>
                      <Col xs={4}>
                        <div style={{ cursor: 'pointer' }} onClick={() => {}}>
                          <IconContext.Provider
                            value={{
                              color: 'orange',
                              size: '30px',
                            }}
                          >
                            <BsFillEraserFill />
                          </IconContext.Provider>
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            saveData()
                          }}
                        >
                          <IconContext.Provider
                            value={{
                              color: 'blue',
                              size: '30px',
                            }}
                          >
                            <FaSave />
                          </IconContext.Provider>
                        </div>
                      </Col>
                      <Col xs={4}></Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '10px' }}>
                  <Col xs={2}>
                    <FloatingLabel controlId="floatingSelect" label="Tipo Operacion">
                      <Form.Select
                        aria-label="Floating"
                        value={tipoOperacion}
                        size="sm"
                        onChange={(e) => {
                          settipoOperacion(parseInt(e.target.value))
                        }}
                      >
                        <option value="0">- Seleccione -</option>
                        <option value="1">Importancion</option>
                        <option value="2">Exportancion</option>
                        <option value="3">Impo / Expo</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col xs={2}>
                    <FloatingLabel controlId="floatingSelect" label="Tipo de embarque">
                      <Form.Select
                        aria-label="Floating"
                        value={tipoEmbarque}
                        size="sm"
                        onChange={(e) => {
                          settipoEmbarque(parseInt(e.target.value))
                        }}
                      >
                        {mCatTipoEmbarque
                          ? mCatTipoEmbarque.map((d, index) => {
                              return <option value={d.id}>{d.embarque}</option>
                            })
                          : ''}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col xs={2}>
                    <FloatingLabel controlId="floatingSelect" label="Patente/Aduana">
                      <Form.Select
                        aria-label="Floating"
                        value={patenteAduana}
                        size="sm"
                        onChange={(e) => {
                          setpatenteAduana(parseInt(e.target.value))
                        }}
                      >
                        {mCatAduanas
                          ? mCatAduanas
                              .filter((d) => {
                                return d.local
                              })
                              .map((d, index) => {
                                return (
                                  <option value={d.id}>
                                    {d.numero.toString() + ' - '} {d.ciudad}
                                  </option>
                                )
                              })
                          : ''}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col xs={2}>
                    <FloatingLabel controlId="floatingSelect" label="TipoPedimento">
                      <Form.Select
                        aria-label="Floating"
                        value={tipoPedimento}
                        size="sm"
                        onChange={(e) => {
                          settipoPedimento(parseInt(e.target.value))
                        }}
                      >
                        {mCatTipoPedimento
                          ? mCatTipoPedimento.map((d, index) => {
                              return <option value={d.id}>{d.pedimento}</option>
                            })
                          : ''}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col xs={2}>
                    <FloatingLabel controlId="floatingSelect" label="Forma de pago">
                      <Form.Select
                        aria-label="Floating"
                        value={formaPago}
                        size="sm"
                        onChange={(e) => {
                          setformaPago(parseInt(e.target.value))
                        }}
                      >
                        {mCatFormaPago
                          ? mCatFormaPago.map((d, index) => {
                              return <option value={d.id}>{d.pago}</option>
                            })
                          : ''}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col xs={2}>
                    <FloatingLabel controlId="floatingSelect" label="Forma de facturacion">
                      <Form.Select
                        aria-label="Floating"
                        value={formaFacturacion}
                        size="sm"
                        onChange={(e) => {
                          setformaFacturacion(parseInt(e.target.value))
                        }}
                      >
                        {mCatFormaFacturacion
                          ? mCatFormaFacturacion.map((d, index) => {
                              return <option value={d.id}>{d.facturacion}</option>
                            })
                          : ''}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '20px' }}>
                  <Col xs={2}>
                    <FloatingLabel controlId="floatingSelect" label="Uso de Almacen">
                      <Form.Select
                        aria-label="Floating"
                        value={usoAlmacen ? 1 : 0}
                        size="sm"
                        onChange={(e) => {
                          setusoAlmacen(parseInt(e.target.value) === 1)
                        }}
                      >
                        <option value="0">No</option>
                        <option value="1">Si</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col xs={2}>
                    <FloatingLabel controlId="floatingInput" label="Pies cuadrados" className="mb-3">
                      <Form.Control
                        type="number"
                        value={piesCuadrados}
                        size="sm"
                        onChange={(e) => {
                          setpiesCuadrados(parseInt(e.target.value))
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={2}>
                    <FloatingLabel controlId="floatingInput" label="Dias de almacenaje" className="mb-3">
                      <Form.Control type="number" />
                    </FloatingLabel>
                  </Col>
                  <Col xs={2}>
                    <FloatingLabel controlId="floatingSelect" label="Logistica">
                      <Form.Select
                        aria-label="Floating"
                        value={logistica ? 1 : 0}
                        size="sm"
                        onChange={(e) => {
                          setlogistica(parseInt(e.target.value) === 1)
                        }}
                      >
                        <option value="0">No</option>
                        <option value="1">Si</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col xs={2}>
                    <FloatingLabel controlId="floatingSelect" label="Distribucion">
                      <Form.Select
                        aria-label="Floating"
                        value={distribucion ? 1 : 0}
                        size="sm"
                        onChange={(e) => {
                          setdistribucion(parseInt(e.target.value) === 1)
                        }}
                      >
                        <option value="0">No</option>
                        <option value="1">Si</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  {/* <Col xs={2}>
                    <FloatingLabel controlId="floatingSelect" label="Operaciones virtuales">
                      <Form.Select
                        aria-label="Floating"
                        value={operacionesVirtuales ? 1 : 0}
                        size="sm"
                        onChange={(e) => {
                          setoperacionesVirtuales(parseInt(e.target.value) === 1)
                        }}
                      >
                        <option value="0">No</option>
                        <option value="1">Si</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col> */}
                  {/*  <Col xs={2}>
                    <FloatingLabel controlId="floatingSelect" label="Bodega fisica">
                      <Form.Select
                        aria-label="Floating"
                        value={bodegaFisica ? 1 : 0}
                        size="sm"
                        onChange={(e) => {
                          setbodegaFisica(parseInt(e.target.value) === 1)
                        }}
                      >
                        <option value="0">No</option>
                        <option value="1">Si</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col> */}
                </Row>
                <Row style={{ paddingTop: '20px' }}>
                  {/*  <Col xs={2}>
                    <FloatingLabel controlId="floatingInput" label="Dias de credito" className="mb-3">
                      <Form.Control
                        type="number"
                        value={diasCredito}
                        size="sm"
                        onChange={(e) => {
                          setdiasCredito(parseInt(e.target.value))
                        }}
                      />
                    </FloatingLabel>
                  </Col> */}
                  <Col xs={2}>
                    <FloatingLabel controlId="floatingInput" label="Pedimentos" className="mb-3">
                      <Form.Control
                        type="number"
                        value={pedimentos}
                        size="sm"
                        onChange={(e) => {
                          setpedimentos(parseInt(e.target.value))
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={2}>
                    <FloatingLabel controlId="floatingInput" label="Guias" className="mb-3">
                      <Form.Control
                        type="number"
                        value={guias}
                        size="sm"
                        onChange={(e) => {
                          setguias(parseInt(e.target.value))
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={2}>
                    <FloatingLabel controlId="floatingInput" label="Fracciones" className="mb-3">
                      <Form.Control
                        type="number"
                        value={fracciones}
                        size="sm"
                        onChange={(e) => {
                          setfracciones(parseInt(e.target.value))
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={2}>
                    <FloatingLabel controlId="floatingInput" label="Partes" className="mb-3">
                      <Form.Control
                        type="number"
                        value={partes}
                        size="sm"
                        onChange={(e) => {
                          setpartes(parseInt(e.target.value))
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={2}>
                    <FloatingLabel controlId="floatingInput" label="Vehiculos" className="mb-3">
                      <Form.Control
                        type="number"
                        value={vehiculos}
                        size="sm"
                        onChange={(e) => {
                          setvehiculos(parseInt(e.target.value))
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '10px' }}>
                  <Col xs={5}>
                    <Select
                      defaultValue={otrasAduanas}
                      onChange={
                        otrasAduanas => {
                          if (Array.isArray(otrasAduanas)) {
                            console.log(otrasAduanas)
                            setOtrasAduanas(otrasAduanas)
                           // setotrasAduanas(otrasAduanas)
                          }
                      } }
                      options={Aduanas}
                      isMulti={true}
                      placeholder="Otras aduanas"
                    />
                  </Col>
                  <Col xs={7}>
                    <FloatingLabel controlId="floatingTextarea" label="Notas" className="mb-3">
                      <Form.Control
                        style={{ height: '70px' }}
                        as="textarea"
                        placeholder="Notas"
                        value={notas}
                        size="sm"
                        onChange={(e) => {
                          setnotas(e.target.value)
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4}>
                    <Card>
                      <Card.Header>Tabulador pactado</Card.Header>
                      <Card.Body>
                        <Card.Text>
                          {IDCliente > 0 ? (
                            <MFileManagerV2
                              Tags={IDCliente}
                              Proceso={1}
                              Usuario={User}
                              Leyenda="Subir archivo"
                              doDelete={true}
                            />
                          ) : (
                            ''
                          )}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={4}>
                    <Card>
                      <Card.Header>Negociaciones especiales</Card.Header>
                      <Card.Body>
                        <Card.Text>
                          {IDCliente > 0 ? (
                            <MFileManagerV2
                              Tags={IDCliente}
                              Proceso={2}
                              Usuario={User}
                              Leyenda="Subir archivo"
                              doDelete={true}
                            />
                          ) : (
                            ''
                          )}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={4}>
                    <Card>
                      <Card.Header>Servicios especiales</Card.Header>
                      <Card.Body>
                        <Card.Text>
                          {IDCliente > 0 ? (
                            <MFileManagerV2
                              Tags={IDCliente}
                              Proceso={3}
                              Usuario={User}
                              Leyenda="Subir archivo"
                              doDelete={true}
                            />
                          ) : (
                            ''
                          )}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
