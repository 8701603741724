import { configureStore } from '@reduxjs/toolkit'
import userStatusReducer from './features/userStatusSlice/userStatusSlice'
import CatDocumentosSlice from './features/Catalogos/CatDocumentosSlice'
import CatClientesSlice from './features/Catalogos/CatClientesSlice'
import CatContactosSlice from './features/Catalogos/CatContactosSlice'
import CatDepartamentosSlice from './features/Catalogos/CatDepartamentosSlice'
import CatAduanaSlice from './features/Catalogos/CatAduanasSlice'
import CatFormaFacturacionSlice from './features/Catalogos/CatFormaFacturacionSlice'
import CatFormaPagoSlice from './features/Catalogos/CatFormaPagoSlice'
import CatTipoEmbarqueSlice from './features/Catalogos/CatTipoEmbarqueSlice'
import CatTipoPedimentoSlice from './features/Catalogos/CatTipoPedimentoSlice'

const store = configureStore({
  reducer: {
    userStatus: userStatusReducer,
    CatDepartamentos: CatDepartamentosSlice,
    CatDocumentos: CatDocumentosSlice,
    CatClientes: CatClientesSlice,
    CatContactos: CatContactosSlice,
    CatAduanas: CatAduanaSlice,
    CatFormaFacturacion: CatFormaFacturacionSlice,
    CatFormaPago: CatFormaPagoSlice,
    CatTipoEmbarque: CatTipoEmbarqueSlice,
    CatTipoPedimento: CatTipoPedimentoSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store
