import { useEffect, useState } from 'react'
import { Button, Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap'
import IConfigEmails from '../Interfaces/IConfigEmails'
import ConfigDS from '../Services/Config.Services'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'

export interface IConfigProps {}

export default function Config(props: IConfigProps) {
  const [NotificacionAA, setNotificacionAA] = useState('')
  const [NotificacionZINC, setNotificacionZINC] = useState('')
  const [NotificacionBroker, setNotificacionBroker] = useState('')
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')

  const loadData = () => {
    ConfigDS.EmailNotifyGET()
      .then((response) => {
        console.log(response.data)
        setNotificacionAA(response.data.notificacionAA)
        setNotificacionZINC(response.data.notificacionZINC)
        setNotificacionBroker(response.data.notificacionBroker)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const saveData = () => {
    const data: IConfigEmails = {
      id: 1,
      notificacionAA: NotificacionAA,
      notificacionZINC: NotificacionZINC,
      notificacionBroker: NotificacionBroker,
    }
    ConfigDS.EmailNotifyUpdate(data)
      .then((response) => {
        setHeader('Informativo')
        setMsg('La informacion se guardo correctamente')
        setShowMsg(true)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <div>
      <Row style={{ paddingTop: '50px' }}>
        <Col xs={4}>&nbsp;</Col>
        <Col xs={4}>
          <Card style={{ textAlign: 'left' }}>
            <Card.Body>
              <Card.Header style={{ fontWeight: 'bold' }}>
                Configure las notificaciones
              </Card.Header>
              <Card.Text>
                <Row style={{ paddingTop: '10px' }}>
                  <Col xs={12}>
                    <FloatingLabel
                      controlId='floatingInput'
                      label='Correos de GEMCO'
                      className='mb-3'
                    >
                      <Form.Control
                        type='email'
                        placeholder='name@example.com'
                        value={NotificacionAA}
                        size='sm'
                        onChange={(e) => {
                          setNotificacionAA(e.target.value)
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '10px' }}>
                  <Col xs={12}>
                    <FloatingLabel
                      controlId='floatingInput'
                      label='Correos de ZINC'
                      className='mb-3'
                    >
                      <Form.Control
                        type='email'
                        placeholder='name@example.com'
                        value={NotificacionZINC}
                        size='sm'
                        onChange={(e) => {
                          setNotificacionZINC(e.target.value)
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '10px' }}>
                  <Col xs={12}>
                    <FloatingLabel
                      controlId='floatingInput'
                      label='Correos del Broker'
                      className='mb-3'
                    >
                      <Form.Control
                        type='email'
                        placeholder='name@example.com'
                        value={NotificacionBroker}
                        size='sm'
                        onChange={(e) => {
                          setNotificacionBroker(e.target.value)
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              </Card.Text>
              <Row>
                <Col xs={9}></Col>
                <Col xs={3}>
                  <Button
                    variant='primary'
                    onClick={() => {
                      saveData()
                    }}
                  >
                    Guarda
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={4}>&nbsp;</Col>
      </Row>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
