import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Row,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { IconContext } from 'react-icons'
import {
  BsFillEraserFill,
  BsFillPencilFill,
  BsXCircleFill,
} from 'react-icons/bs'
import { FaSave } from 'react-icons/fa'
import Slider from 'rc-slider'
import CatalogService from '../../Services/Catalogos.Services'
import 'rc-slider/assets/index.css'
import { useEffect, useState } from 'react'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'
import { useParams } from 'react-router-dom'
import { RootState } from '../../../../store/store'
import {
  addCatClientes,
  updateCatClientes,
} from '../../../../store/features/Catalogos/CatClientesSlice'
import { RxDoubleArrowRight } from 'react-icons/rx'
import IContacto from '../../Interfaces/ICatContacto'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'
import { AiFillCheckCircle } from 'react-icons/ai'
import ICatCliente from '../../Interfaces/ICatCliente'
import { updateCatContacto } from '../../../../store/features/Catalogos/CatContactosSlice'

export interface IDatosGeneralesProps {}

export default function DatosGenerales(props: IDatosGeneralesProps) {
  const { id } = useParams()
  let navigate = useNavigate()
  const mCatClientes = useSelector(
    (state: RootState) => state.CatClientes.CatClientes
  )
  const mCatContactos = useSelector(
    (state: RootState) => state.CatContactos.CatContacto
  )
  const dispatch = useDispatch()
  const [IDCliente, setIDCliente] = useState(0)
  const [IDContacto, setIDContacto] = useState(0)
  const [NombreComercial, setNombreComercial] = useState('')
  const [RazonSocial, setRazonSocial] = useState('')
  const [RFC, setRFC] = useState('')
  const [Activo, setActivo] = useState(false)
  const [Nombre, setNombre] = useState('')
  const [Correo, setCorreo] = useState('')
  const [Tel, setTel] = useState('')
  const [Vendedor, setVendedor] = useState(0)
  const [Whattsapp, setWhattsapp] = useState(false)
  const [ContactoActivo, setContactoActivo] = useState(false)
  const [Contactos, setContactos] = useState<IContacto[]>([])
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')
  const wrapperStyle = { width: '100%', margin: 10 }
  const dataContactos = [
    {
      name: 'id',
      width: '5%',
      selector: (row: IContacto) => row.id,
      sortable: true,
    },
    {
      name: 'Activo',
      width: '5%',
      cell: (row: IContacto) => (
        <div>
          <IconContext.Provider
            value={{ color: row.activo ? 'green' : 'red', size: '25px' }}
          >
            {row.activo ? <AiFillCheckCircle /> : <BsXCircleFill />}
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'Nombre',
      width: '30%',
      selector: (row: IContacto) => row.nombre,
    },
    {
      name: 'Correo',
      width: '30%',
      selector: (row: IContacto) => row.correo,
    },
    {
      name: 'Telefono',
      width: '10%',
      selector: (row: IContacto) => row.telefono,
    },
    {
      name: 'Tiene Whattsapp',
      width: '10%',
      selector: (row: IContacto) => (row.whattsapp ? 'Si' : 'No'),
    },
    {
      name: 'Edita',
      width: '5%',
      cell: (row: IContacto) => (
        <div
          style={{ textAlign: 'right', cursor: 'pointer' }}
          onClick={() => {
            setIDContacto(row.id)
            setNombre(row.nombre)
            setTel(row.telefono)
            setCorreo(row.correo)
            setWhattsapp(row.whattsapp)
            setContactoActivo(row.activo)
          }}
        >
          <IconContext.Provider value={{ color: 'blue', size: '25px' }}>
            <BsFillPencilFill />
          </IconContext.Provider>
        </div>
      ),
    },
  ]

  const saveDataCliente = () => {
    const data: ICatCliente = {
      id: IDCliente,
      nombreComercial: NombreComercial ? NombreComercial : '',
      razonSocial: RazonSocial ? RazonSocial : '',
      rfc: RFC ? RFC : '',
      tipoCliente: 1,
      activo: Activo,
      vendedor: Vendedor,
      descripcionMercancia: '',
      patenteAduana: 0,
      tipoOperacion: 0,
      usoAlmacen: false,
      piesCuadrados: 0,
      diasAlmacenaje: 0,
      tipoEmbarque: 0,
      logistica: false,
      operacionesVirtuales: false,
      bodegaFisica: false,
      tipoPedimento: 0,
      formaPago: 0,
      formaFacturacion: 0,
      diasCredito: 0,
      pedimentos: 0,
      guias: 0,
      fracciones: 0,
      partes: 0,
      vehiculos: 0,
      notas: ''
    }
    CatalogService.CatClientesAppend(data)
      .then((response) => {
        setHeader('Confirmacion')
        setMsg('Los cambios se han guardado exitosamente.')
        if (IDCliente === response.data.id) dispatch(updateCatClientes(data))
        else {
          dispatch(addCatClientes(data))
          setIDCliente(response.data.id)
        }
        setShowMsg(true)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const saveDataContactos = () => {
    if (Nombre.length===0) {
      setHeader('Error')
      setMsg('Proporcione el nombre del contacto')
      setShowMsg(true)
      return
    }
    if (Tel.length===0) {
      setHeader('Error')
      setMsg('Proporcione el telefono del contacto')
      setShowMsg(true)
      return
    }
    const data: IContacto = {
      id: IDContacto,
      idCliente: IDCliente,
      nombre: Nombre,
      correo: Correo,
      telefono: Tel,
      departamento: 0,
      whattsapp: Whattsapp,
      activo: ContactoActivo,
    }
    console.log('data del contacto='+JSON.stringify(data))
    CatalogService.CatContactosAppend(data)
      .then((response) => {
        dispatch(updateCatContacto(data))
        setHeader('Confirmacion')
        setMsg('Los cambios se han guardado exitosamente.')
        setShowMsg(true)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const cleanFormCliente = () => {
    setIDCliente(0)
    setNombreComercial('')
    setRazonSocial('')
    setRFC('')
    setActivo(false)
  }

  const cleanFormContactos = () => {
    setIDContacto(0)
    setNombre('')
    setTel('')
    setCorreo('')
    setWhattsapp(true)
    setContactoActivo(false)
  }

  useEffect(() => {
    if (id) {
      if (parseInt(id) > 0) {
        let data = mCatClientes.filter(function (item) {
          return item.id === parseInt(id)
        })
        console.log(data)
        setIDCliente(data[0].id)
        setNombreComercial(data[0].nombreComercial)
        setRazonSocial(data[0].razonSocial)
        setRFC(data[0].rfc)
        setVendedor(data[0].vendedor ? data[0].vendedor : 0)
        setActivo(data[0].activo)
      }
    }
  }, [id])

  const loadContactos = () => {
    CatalogService.CatContactosGet()
      .then((response) => {
        setContactos(response.data)
      })
      .catch((e: Error) => {
        return
      })
  }

  useEffect(() => {
    if (IDCliente > 0) loadContactos()
  }, [IDCliente])

  const avanzaProceso = () => {
    console.log(IDCliente)
    CatalogService.CatClientesValidaProceso(IDCliente, 1)
    .then((response) => {
      console.log(response.data)
      if (response.data) navigate('/FichaTecnica/' + IDCliente)
      else {
        setHeader('Error')
        setMsg('Para poder continuar es necesario que la informacion de todos los campos este llena')
        setShowMsg(true)
        return
      }
    })
    .catch((e: Error) => {
      setHeader('Error')
      setMsg('Ocurrio un error: ' + e)
      setShowMsg(true)
      return
    })
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Card border='dark' className='shadow-lg p-3 mb-5 bg-white rounded'>
            <Card.Header as='h4'>Datos generales del cliente</Card.Header>
            <Card.Body>
              <Card.Text>
                <Row>
                  <Col xs={3}></Col>
                  <Col xs={6}>
                    <div style={wrapperStyle}>
                      <Slider
                        dotStyle={{ borderColor: 'orange' }}
                        handleStyle={{
                          borderColor: 'black',
                          height: 20,
                          width: 20,
                          marginLeft: 0,
                          marginTop: -9,
                          backgroundColor: 'red',
                        }}
                        railStyle={{ height: 2 }}
                        min={0}
                        max={7}
                        dots
                        defaultValue={0}
                        marks={{
                          0: 'Datos generales',
                          1: 'Fecha tecnica',
                          2: 'Reunion interna',
                          3: 'Solicitar docs',
                          4: 'Reunion cliente',
                          5: 'Registro de docs',
                          6: 'Compliance',
                          7: 'Generacion MAC',
                        }}
                        step={null}
                      />
                    </div>
                  </Col>
                  <Col xs={3} style={{ textAlign: 'right' }}>
                    <Button
                      onClick={() => {
                        avanzaProceso()                        
                      }}
                    >
                      Continue
                      <IconContext.Provider
                        value={{
                          color: 'white',
                          size: '20px',
                        }}
                      >
                        <RxDoubleArrowRight />
                      </IconContext.Provider>
                    </Button>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '30px' }}>
                  <Col xs={9}>
                    <FloatingLabel
                      controlId='floatingNombreComercial'
                      label='Nombre comercial'
                    >
                      <Form.Control
                        type='text'
                        placeholder='Nombre comercial'
                        value={NombreComercial}
                        size='sm'
                        onChange={(e) => {
                          setNombreComercial(e.target.value)
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={2}>
                    <FloatingLabel controlId='floatingSelect' label='Vendedor'>
                      <Form.Select
                        aria-label='Floating esta activo'
                        value={Vendedor}
                        size='sm'
                        onChange={(e) => {
                          setVendedor(parseInt(e.target.value))
                        }}
                      >
                        <option value='0'>-NO ASIGNADO-</option>
                        {mCatContactos
                          ? mCatContactos.map((c) => {
                              if (c.idCliente === IDCliente) {
                                return (
                                  <option key={c.id} value={c.id}>
                                    {c.nombre}
                                  </option>
                                )
                              }
                            })
                          : null}
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '10px' }}>
                  <Col xs={9}>
                    <FloatingLabel
                      controlId='floatingRazonSocial'
                      label='Razon Social'
                    >
                      <Form.Control
                        type='text'
                        placeholder='RazonSocial'
                        value={RazonSocial}
                        size='sm'
                        onChange={(e) => {
                          setRazonSocial(e.target.value)
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={1}>
                    <FloatingLabel controlId='floatingRFC' label='RFC'>
                      <Form.Control
                        type='text'
                        placeholder='RFC'
                        value={RFC}
                        size='sm'
                        onChange={(e) => {
                          setRFC(e.target.value)
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={1}>
                    <FloatingLabel
                      controlId='floatingSelect'
                      label='Cliente activo?'
                    >
                      <Form.Select
                        aria-label='Floating esta activo'
                        value={Activo ? 1 : 0}
                        size='sm'
                        onChange={(e) => {
                          setActivo(parseInt(e.target.value) === 1)
                        }}
                      >
                        <option value='1'>Si</option>
                        <option value='0'>No</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col xs={1}>
                    <Row>
                      <Col xs={5}>
                        <div
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            cleanFormCliente()
                          }}
                        >
                          <IconContext.Provider
                            value={{
                              color: 'orange',
                              size: '30px',
                            }}
                          >
                            <BsFillEraserFill />
                          </IconContext.Provider>
                        </div>
                      </Col>
                      <Col xs={2}></Col>
                      <Col xs={5}>
                        <div
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            saveDataCliente()
                          }}
                        >
                          <IconContext.Provider
                            value={{
                              color: 'blue',
                              size: '30px',
                            }}
                          >
                            <FaSave />
                          </IconContext.Provider>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '10px' }}>
                  <Col xs={12}>
                    <Card className='shadow p-3 mb-5 bg-white rounded'>
                      <Card.Header as='h5'>Contactos</Card.Header>
                      <Card.Body>
                        <Card.Text>
                          <Card>
                            <Card.Body>
                              <Card.Text>
                                <Row>
                                  <Col xs={4}>
                                    <FloatingLabel
                                      controlId='floatingNombre'
                                      label='Nombre'
                                    >
                                      <Form.Control
                                        type='text'
                                        placeholder='Nombre'
                                        value={Nombre}
                                        size='sm'
                                        onChange={(e) => {
                                          setNombre(e.target.value)
                                        }}
                                      />
                                    </FloatingLabel>
                                  </Col>
                                  <Col xs={3}>
                                    <FloatingLabel
                                      controlId='floatingNombre'
                                      label='Correo'
                                    >
                                      <Form.Control
                                        type='email'
                                        placeholder='Correo'
                                        value={Correo}
                                        size='sm'
                                        onChange={(e) => {
                                          setCorreo(e.target.value)
                                        }}
                                      />
                                    </FloatingLabel>
                                  </Col>
                                  <Col xs={2}>
                                    <FloatingLabel
                                      controlId='floatingTel'
                                      label='Telefono'
                                    >
                                      <Form.Control
                                        type='text'
                                        placeholder='Tel'
                                        value={Tel}
                                        size='sm'
                                        onChange={(e) => {
                                          setTel(e.target.value)
                                        }}
                                      />
                                    </FloatingLabel>
                                  </Col>
                                  <Col xs={1}>
                                    <FloatingLabel
                                      controlId='floatingSelect'
                                      label='¿Whattapp?'
                                    >
                                      <Form.Select
                                        aria-label='Floating tiene whattapp'
                                        value={Whattsapp ? 1 : 0}
                                        size='sm'
                                        onChange={(e) => {
                                          setWhattsapp(
                                            parseInt(e.target.value) === 1
                                          )
                                        }}
                                      >
                                        <option value='0'>No</option>
                                        <option value='1'>Si</option>
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Col>
                                  <Col xs={1}>
                                    <FloatingLabel
                                      controlId='floatingSelect'
                                      label='¿Activo?'
                                    >
                                      <Form.Select
                                        aria-label='Floating esta activo'
                                        value={ContactoActivo ? 1 : 0}
                                        size='sm'
                                        onChange={(e) => {
                                          setContactoActivo(
                                            parseInt(e.target.value) === 1
                                          )
                                        }}
                                      >
                                        <option value='1'>Si</option>
                                        <option value='0'>No</option>
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Col>
                                  <Col xs={1}>
                                    <Row style={{ paddingTop: '15px' }}>
                                      <Col xs={6}>
                                        <div
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            cleanFormContactos()
                                          }}
                                        >
                                          <IconContext.Provider
                                            value={{
                                              color: 'orange',
                                              size: '30px',
                                            }}
                                          >
                                            <BsFillEraserFill />
                                          </IconContext.Provider>
                                        </div>
                                      </Col>
                                      <Col xs={6}>
                                        <div
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                          onClick={() => {
                                            saveDataContactos()
                                          }}
                                        >
                                          <IconContext.Provider
                                            value={{
                                              color: 'blue',
                                              size: '30px',
                                            }}
                                          >
                                            <FaSave />
                                          </IconContext.Provider>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <DataTable
                      noHeader
                      defaultSortFieldId={'id'}
                      defaultSortAsc={true}
                      pagination
                      dense={true}
                      striped={true}
                      responsive={true}
                      highlightOnHover
                      columns={dataContactos}
                      data={mCatContactos.filter(function (el) {
                        return el.idCliente === IDCliente
                      })}
                    />
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={5000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
