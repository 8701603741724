import { number } from 'yup'
import DTOClienteTransportista from '../../../DTO/DTOClienteTransportita'
import http from '../../../Services/common/http-common'
import DTOCatCliente from '../DTO/DTOCatCliente'
import DTODocumento from '../DTO/DTODocumento'
import DTOFichaTecnica from '../DTO/DTOFichaTecnica'
import ICalendario from '../Interfaces/ICalendario'
import ICatAduana from '../Interfaces/ICatAduana'
import ICatCliente from '../Interfaces/ICatCliente'
import IContacto from '../Interfaces/ICatContacto'
import ICatDepartamento from '../Interfaces/ICatDepartamento'
import ICatDocumento from '../Interfaces/ICatDocumento'
import ICatFormaFacturacion from '../Interfaces/ICatFormaFacturacion'
import ICatFormaPago from '../Interfaces/ICatFormaPago'
import ICatTipoEmbarque from '../Interfaces/ICatTipoEmbarque'
import ICatTipoPedimento from '../Interfaces/ICatTipoPedimento'

class CatalogosDataService {
  /* ↓ ----------------------------------- ↓ Catalogos varios ↓ ----------------------------------------- ↓ */
  CatDepartamentosGet() {
    return http.get<ICatDepartamento[]>(
      `/Catalogos/Departamentos/Get`
    )
  }
  CatAduanasGet() {
    return http.get<ICatAduana[]>(
      `/Catalogos/Aduanas/Get`
    )
  }
  CatTipoEmbarqueGet() {
    return http.get<ICatTipoEmbarque[]>(
      `/Catalogos/TipoEmbarques/Get`
    )
  }
  CatTipoPedimentoGet() {
    return http.get<ICatTipoPedimento[]>(
      `/Catalogos/TipoPedimentos/Get`
    )
  }
  CatFormaPagoGet() {
    return http.get<ICatFormaPago[]>(
      `/Catalogos/FormaPago/Get`
    )
  }
  CatFormaFacturacionGet() {
    return http.get<ICatFormaFacturacion[]>(
      `/Catalogos/FormaFacturacion/Get`
    )
  }
  CalendarioGet() {
    return http.get<ICalendario[]>(
      `/Catalogos/Calendario/Get`
    )
  }
  CalendarioAppend(data: ICalendario) {
    return http.post<ICalendario>(
      `/Catalogos/Calendario/Append`, data
    )
  }
  CalendarioDelete(id: number) {
    return http.delete<Boolean>(
      `/Catalogos/Calendario/Delete?id=${id}`
    )
  }
  CalendarioSend(EventNo: number, mensajeCorreo: string) {
    return http.get<Boolean>(
      `/ICal/ICalEventSend?EventNo=${EventNo}&Mensaje=${mensajeCorreo}`
    )
  }


  /* ↑ --------------------------------- ↑ Catalogos varios ↑ -------------------------------------------- ↓ */


  /* ↓ --------------------------------- ↓ Catalogo de Documentos ↓ --------------------------------------- ↓ */
  CatDocumentosGetAll() {
    return http.get<ICatDocumento[]>(
      `/Catalogos/Documentos/CatDocumentosGetAll`
    )
  }
  CatDocumentoAppend(data: ICatDocumento) {
    return http.post<ICatDocumento>(
      `/Catalogos/Documentos/CatDocumentosAppend`,
      data
    )
  }
  /* ↑ --------------------------------- ↑ Catalogo de Documentos ↑ ---------------------------------------- ↓ */

  /* ↓ --------------------------------- ↓ Catalogo de Clientes ↓ ----------------------------------------- ↓ */
  CatClientesGetAll() {
    return http.get<ICatCliente[]>(`/Catalogos/Clientes/CatClientesGetAll`)
  }
  CatClientesAppend(data: DTOCatCliente) {
    return http.post<DTOCatCliente>(
      `/Catalogos/Clientes/DatosGeneralesAppend`,
      data
    )
  }
  FichaTecnicaAppend(data: DTOFichaTecnica) {
    return http.post<boolean>(`/Catalogos/Clientes/FichaTecnica/Append`, data)
  }
  FichaTecnicaGet(id: number) {
    return http.get<DTOFichaTecnica>(
      `/Catalogos/Clientes/FichaTecnica/Get?id=${id}`
    )
  }
  CatClientesValidaProceso(IDCliente: number, IDProceso: number) {
    return http.get<Boolean>(`/Catalogos/Clientes/ValidaProceso/Get?IdCliente=${IDCliente}&IdProceso=${IDProceso}`)
  }
  CatClientesDocumentosGet(IDCliente: number) {
    return http.get<DTODocumento[]>(`/Catalogos/Clientes/Documentos/Get?IdCliente=${IDCliente}`)
  }
  CatClientesDocumentoToggle(IDDoc: number) {
    return http.put<DTODocumento[]>(`/Catalogos/Clientes/Documento/Toggle?IdDoc=${IDDoc}`)
  }
  /* ↑ --------------------------------- ↑ Catalogo de Clientes ↑ -------------------------------------------- ↓ */

  /* ↓ ----------------------------------- ↓ Catalogo de Contactos ↓ ----------------------------------------- ↓ */
  CatContactosGet() {
    return http.get<IContacto[]>(
      `/Catalogos/Contactos/Get`
    )
  }

  CatContactosAppend(data: IContacto) {
    return http.post<IContacto>(`/Catalogos/Contactos/Append`, data)
  }

  /* ↑ --------------------------------- ↑ Catalogo de Contactos ↑ -------------------------------------------- ↓ */
}
export default new CatalogosDataService()
