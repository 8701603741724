import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICatFormaFacturacion from '../../../Components/Cliente/Interfaces/ICatFormaFacturacion'
const CatFormaFacturacion: ICatFormaFacturacion[] = [
  {
    id: 0,
    facturacion: '- Seleccione -',
    activo: false
  },
]
const initialState = { CatFormaFacturacion }

export const CatFormaFacturacionSlice = createSlice({
  name: 'CatFormaFacturacion',
  initialState: initialState,
  reducers: {
    populateCatFormaFacturacion: (state, action: PayloadAction<ICatFormaFacturacion[]>) => {
      state.CatFormaFacturacion = []
      state.CatFormaFacturacion =  [
        {
          id: 0,
          facturacion: '- Seleccione -',
          activo: false
        },
      ]
      state.CatFormaFacturacion.push(...action.payload)
    },
    addCatFormaFacturacion: (state, action: PayloadAction<ICatFormaFacturacion>) => {
      var Existe = state.CatFormaFacturacion.find(function (item) {
        return item.id === action.payload.id
      })
      if (!Existe) state.CatFormaFacturacion.push(action.payload)
    },
    updateCatFormaFacturacion: (state, action: PayloadAction<ICatFormaFacturacion>) => {
      const i = state.CatFormaFacturacion.findIndex(
        (_element) => _element.id === action.payload.id
      )
      if (i > -1) state.CatFormaFacturacion[i] = action.payload
      else state.CatFormaFacturacion.push(action.payload)
    },
    deleteCatFormaFacturacion: (state, action: PayloadAction<number>) => {
      const newArr = state.CatFormaFacturacion.filter(
        (data) => data.id != action.payload
      )
      state.CatFormaFacturacion = newArr
    },
  },
})

export const {
  addCatFormaFacturacion,
  populateCatFormaFacturacion,
  updateCatFormaFacturacion,
  deleteCatFormaFacturacion,
} = CatFormaFacturacionSlice.actions
export default CatFormaFacturacionSlice.reducer
