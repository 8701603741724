import http from '../common/http-common'
import IRespuesta from '../../Interfaces/IRespuesta'
import IFileManager from '../../Interfaces/Utils/IFileManager'

class MFileManagerDataService {
  Append(formData: any, IDTrafico: number, Proceso: number, Usuario: number) {
    return http.post<IFileManager[]>(
      `/Utils/MFileManager/Append?Tags=${IDTrafico}&Proceso=${Proceso}&Usuario=${Usuario}`,
      formData
    )
  }
  Append2(formData: any) {
    return http.post<IFileManager[]>(`/Utils/MFileManager/Append`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
  DeleteFile(id: number) {
    return http.delete<IRespuesta>(`/FileManager/DeleteById/${id}`)
  }
  Get(Tags: number, Proceso: number) {
    return http.get<IFileManager[]>(
      `/Utils/MFileManager/GetFilesFromLog?Tags=${Tags}&Proceso=${Proceso}`
    )
  }
  getFileContentById(id: number, Proceso: number) {
    return http
      .get<ArrayBuffer>(
        `/Utils/MFileManager/GetFileContentById?id=${id}&Proceso=${Proceso}`,
        { responseType: 'arraybuffer' }
      )
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        console.log(error)
      })
  }
}
export default new MFileManagerDataService()
