import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICatTipoEmbarque from '../../../Components/Cliente/Interfaces/ICatTipoEmbarque'
const CatTipoEmbarque: ICatTipoEmbarque[] = [
  {
    id: 0,
    embarque: '- Seleccione -',
    activo: false,
  },
]
const initialState = { CatTipoEmbarque }

export const CatTipoEmbarqueSlice = createSlice({
  name: 'CatTipoEmbarque',
  initialState: initialState,
  reducers: {
    populateCatTipoEmbarque: (state, action: PayloadAction<ICatTipoEmbarque[]>) => {
      state.CatTipoEmbarque = []
      state.CatTipoEmbarque = [
        {
          id: 0,
          embarque: '- Seleccione -',
          activo: false,
        },
      ]
      state.CatTipoEmbarque.push(...action.payload)
    },
    addCatTipoEmbarque: (state, action: PayloadAction<ICatTipoEmbarque>) => {
      var Existe = state.CatTipoEmbarque.find(function (item) {
        return item.id === action.payload.id
      })
      if (!Existe) state.CatTipoEmbarque.push(action.payload)
    },
    updateCatTipoEmbarque: (state, action: PayloadAction<ICatTipoEmbarque>) => {
      const i = state.CatTipoEmbarque.findIndex((_element) => _element.id === action.payload.id)
      if (i > -1) state.CatTipoEmbarque[i] = action.payload
      else state.CatTipoEmbarque.push(action.payload)
    },
    deleteCatTipoEmbarque: (state, action: PayloadAction<number>) => {
      const newArr = state.CatTipoEmbarque.filter((data) => data.id != action.payload)
      state.CatTipoEmbarque = newArr
    },
  },
})

export const { addCatTipoEmbarque, populateCatTipoEmbarque, updateCatTipoEmbarque, deleteCatTipoEmbarque } =
  CatTipoEmbarqueSlice.actions
export default CatTipoEmbarqueSlice.reducer
