import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICatCliente from '../../../Components/Cliente/Interfaces/ICatCliente'
const CatClientes: ICatCliente[] = [
  {
    id: 0,
    nombreComercial: '- Seleccione -',
    razonSocial: '- Seleccione -',
    rfc: '',
    tipoCliente: 1,
    estatus: 0,
    vendedor: 0,
    descripcionMercancia: '',
    patenteAduana: 0,
    tipoOperacion: 0,
    usoAlmacen: false,
    piesCuadrados: 0,
    diasAlmacenaje: 0,
    tipoEmbarque: 0,
    logistica: false,
    operacionesVirtuales: false,
    bodegaFisica: false,
    tipoPedimento: 0,
    formaPago: 0,
    formaFacturacion: 0,
    diasCredito: 0,
    pedimentos: 0,
    guias: 0,
    fracciones: 0,
    partes: 0,
    vehiculos: 0,
    notas: '',
    activo: false
  },
]
const initialState = { CatClientes }

export const CatClientesSlice = createSlice({
  name: 'CatClientes',
  initialState: initialState,
  reducers: {
    populateCatClientes: (state, action: PayloadAction<ICatCliente[]>) => {
      state.CatClientes = []
      state.CatClientes.push(...action.payload)
    },
    addCatClientes: (state, action: PayloadAction<ICatCliente>) => {
      var Existe = state.CatClientes.find(function (item) {
        return item.id === action.payload.id
      })
      if (!Existe) state.CatClientes.push(action.payload)
    },
    updateCatClientes: (state, action: PayloadAction<ICatCliente>) => {
      const i = state.CatClientes.findIndex(
        (_element) => _element.id === action.payload.id
      )
      if (i > -1) state.CatClientes[i] = action.payload
      else state.CatClientes.push(action.payload)
    },
    deleteCatClientes: (state, action: PayloadAction<number>) => {
      const newArr = state.CatClientes.filter(
        (data) => data.id != action.payload
      )
      state.CatClientes = newArr
    },
  },
})

export const {
  addCatClientes,
  populateCatClientes,
  updateCatClientes,
  deleteCatClientes,
} = CatClientesSlice.actions
export default CatClientesSlice.reducer
