import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICatFormaPago from '../../../Components/Cliente/Interfaces/ICatFormaPago'
const CatFormaPago: ICatFormaPago[] = [
  {
    id: 0,
    pago: '- Seleccione -',
    activo: false,
  },
]
const initialState = { CatFormaPago }

export const CatFormaPagoSlice = createSlice({
  name: 'CatFormaPago',
  initialState: initialState,
  reducers: {
    populateCatFormaPago: (state, action: PayloadAction<ICatFormaPago[]>) => {
      state.CatFormaPago = []
      state.CatFormaPago = [
        {
          id: 0,
          pago: '- Seleccione -',
          activo: false,
        },
      ]
      state.CatFormaPago.push(...action.payload)
    },
    addCatFormaPago: (state, action: PayloadAction<ICatFormaPago>) => {
      var Existe = state.CatFormaPago.find(function (item) {
        return item.id === action.payload.id
      })
      if (!Existe) state.CatFormaPago.push(action.payload)
    },
    updateCatFormaPago: (state, action: PayloadAction<ICatFormaPago>) => {
      const i = state.CatFormaPago.findIndex((_element) => _element.id === action.payload.id)
      if (i > -1) state.CatFormaPago[i] = action.payload
      else state.CatFormaPago.push(action.payload)
    },
    deleteCatFormaPago: (state, action: PayloadAction<number>) => {
      const newArr = state.CatFormaPago.filter((data) => data.id != action.payload)
      state.CatFormaPago = newArr
    },
  },
})

export const { addCatFormaPago, populateCatFormaPago, updateCatFormaPago, deleteCatFormaPago } =
  CatFormaPagoSlice.actions
export default CatFormaPagoSlice.reducer
