import React from 'react'
import ReactDOM from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store/store'

import Config from './Components/Cliente/Catalogos/Config'
import Dashboard from './Components/Dashboard/Dashboard'
import Help from './Components/Help/Help'
import Login from './Components/Login/Login'
import Logout from './Components/Logout/logout'
import ResetCredentials from './Components/Login/ResetCredentials'
import Register from './Components/Register/register'
import UserControl from './Components/UserControl/UserControl'
import ForgotPassword from './Components/Login/ForgotPassword'
import PIN from './Components/Login/PIN'
import DatosGenerales from './Components/Cliente/Secuencia/DatosGenerales/DatosGenerales'
import CatDocumentos from './Components/Cliente/Catalogos/CatDocumentos'
import CatClientes from './Components/Cliente/Catalogos/CatClientes'
import FichaTecnica from './Components/Cliente/Secuencia/FichaTecnica/FichaTecnica'
import ReunionInterna from './Components/Cliente/Secuencia/ReunionInterna/ReunionInterna'
import RegistroDocumentos from './Components/Cliente/Secuencia/RegistroDocumentos/RegistroDocumentos'
import CatContactos from './Components/Cliente/Catalogos/CatContactos'
import SolicitudDocumentos from './Components/Cliente/Secuencia/SolicitudDocumentos/SolicitudDocumentos'
import ReunionCliente from './Components/Cliente/Secuencia/ReunionCliente/ReunionCliente'
import Compliance from './Components/Cliente/Secuencia/Compliance/Compliance'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

function PageNotFound() {
  return (
    <div style={{ textAlign: 'center', paddingTop: '300px' }}>
      <h2>Sorry, no matching page</h2>
    </div>
  )
}

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <HashRouter>
        <Routes>
          <Route path='/' element={<App />}>
            <Route path='/' element={<Dashboard />} />
            <Route path='/login' element={<Login />} />
            <Route path='/forgotPassword' element={<ForgotPassword />} />
            <Route path='/PIN' element={<PIN />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/help' element={<Help />} />
            <Route path='/Register' element={<Register />} />
            <Route path='/DatosGenerales/:id' element={<DatosGenerales />} />
            <Route path='/CatClientes' element={<CatClientes />} />
            <Route path='/CatDocumentos' element={<CatDocumentos />} />
            <Route path='/CatContactos' element={<CatContactos />} />
            <Route path='/ReunionInterna/:id' element={<ReunionInterna />} />
            <Route path='/FichaTecnica/:id' element={<FichaTecnica />} />
            <Route path='/SolicitudDocumentos/:id' element={<SolicitudDocumentos />} />
            <Route path='/ReunionCliente/:id' element={<ReunionCliente />} />
            <Route path='/RegistroDocumentos/:id' element={<RegistroDocumentos />} />
            <Route path='/Compliance/:id' element={<Compliance />} />
            <Route path='/UserControl' element={<UserControl />} />
            <Route path='/Reset' element={<ResetCredentials />} />
            <Route path='/Config' element={<Config />} />
            <Route path='*' element={<PageNotFound />} />
          </Route>
        </Routes>
      </HashRouter>
    </React.StrictMode>
  </Provider>
)
reportWebVitals()
