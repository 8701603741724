import React, { FC, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { IconContext } from 'react-icons'
import { BsEraserFill, BsFillPencilFill, BsXCircleFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import ICatDocumentos from '../Interfaces/ICatDocumento'
import CatalogService from '../Services/Catalogos.Services'
import { RootState } from '../../../store/store'
import {
  addCatDocumentos,
  updateCatDocumentos,
} from '../../../store/features/Catalogos/CatDocumentosSlice'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import { AiFillCheckCircle } from 'react-icons/ai'
export interface ICatDocumentosProps {}

export default function CatDocumentos(props: ICatDocumentosProps) {
  const dispatch = useDispatch()
  const mCatDocumentos = useSelector(
    (state: RootState) => state.CatDocumentos.CatDocumentos
  )
  const [ID, setID] = useState(0)
  const [Nombre, setNombre] = useState('')
  const [Activo, setActivo] = useState(false)
  const [Buscar, setBuscar] = useState('')
  const [show, setShowMsg] = useState(false)
  const [ShowModal, setShowModal] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')

  const colData = [
    {
      name: 'id',
      width: '5%',
      selector: (row: ICatDocumentos) => row.id,
      sortable: true,
    },
    {
      name: 'Activo',
      width: '5%',
      cell: (row: ICatDocumentos) => (
        <div>
          <IconContext.Provider
            value={{ color: row.activo ? 'green' : 'red', size: '25px' }}
          >
            {row.activo ? <AiFillCheckCircle /> : <BsXCircleFill />}
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'Documento',
      width: '85%',
      selector: (row: ICatDocumentos) => row.nombre,
    },
    {
      name: 'Edita',
      width: '5%',
      cell: (row: ICatDocumentos) => (
        <div
          style={{ textAlign: 'right', cursor: 'pointer' }}
          onClick={() => {
            setID(row.id)
            setNombre(row.nombre)
            setActivo(row.activo)
          }}
        >
          <IconContext.Provider value={{ color: 'blue', size: '25px' }}>
            <BsFillPencilFill />
          </IconContext.Provider>
        </div>
      ),
    },
  ]

  const save = () => {
    const data: ICatDocumentos = {
      id: ID,
      nombre: Nombre ? Nombre : '',
      activo: Activo ? Activo : false,
    }
    CatalogService.CatDocumentoAppend(data)
      .then((response) => {
        setHeader('Confirmacion')
        setMsg('Los cambios se han guardado exitosamente.')
        if (ID > 0) dispatch(updateCatDocumentos(data))
        dispatch(addCatDocumentos(data))
        setShowMsg(true)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const cleanForm = () => {
    setID(0)
    setNombre('')
    setActivo(false)
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Card>
            <Card.Body>
              <Card.Text>
                <Row>
                  <Col xs={1}>Documento</Col>
                  <Col xs={7}>
                    <Form.Control
                      type='text'
                      id='Nombre'
                      value={Nombre}
                      size='sm'
                      onChange={(e) => {
                        setNombre(e.target.value)
                      }}
                    />
                  </Col>
                  <Col xs={1}>Activo</Col>
                  <Col xs={1}>
                    <Form.Select
                      aria-label='Activo'
                      value={Activo ? 1 : 0}
                      size='sm'
                      onChange={() => {
                        setActivo(!Activo)
                      }}
                    >
                      <option value='1'>Si</option>
                      <option value='0'>No</option>
                    </Form.Select>
                  </Col>
                  <Col xs={1} style={{ textAlign: 'left' }}>
                    <Button
                      variant='warning'
                      size='sm'
                      onClick={() => {
                        cleanForm()
                      }}
                    >
                      <BsEraserFill />
                      &nbsp;Limpiar
                    </Button>
                  </Col>
                  <Col xs={1} style={{ textAlign: 'right' }}>
                    <Button variant='primary' onClick={() => save()} size='sm'>
                      Guardar
                    </Button>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '5px' }}></Row>
                <Row style={{ paddingTop: '5px' }}>
                  <Col xs={12}>
                    <Form.Control
                      type='text'
                      id='Buscar'
                      value={Buscar}
                      placeholder='Search...'
                      size='sm'
                      onChange={(e) => {
                        setBuscar(e.target.value)
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <DataTable
                      noHeader
                      defaultSortFieldId={'id'}
                      defaultSortAsc={true}
                      pagination
                      dense={true}
                      striped={true}
                      responsive={true}
                      highlightOnHover
                      columns={colData}
                      data={mCatDocumentos.filter(function (row) {
                        return row.id > 0
                          ? row.nombre
                              .toLowerCase()
                              .includes(Buscar.toLowerCase())
                          : null
                      })}
                    />
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>

      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
