import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICatContacto from '../../../Components/Cliente/Interfaces/ICatContacto'
const CatContacto: ICatContacto[] = [
  { id: 0, idCliente:0,  nombre: '- Seleccione destino -', correo: '', telefono: '', departamento:0, whattsapp: false, activo: false },
]
const initialState = { CatContacto }

export const CatContactoSlice = createSlice({
  name: 'CatContacto',
  initialState: initialState,
  reducers: {
    populateCatContacto: (state, action: PayloadAction<ICatContacto[]>) => {
      state.CatContacto = []
      state.CatContacto.push(...action.payload)
    },
    addCatContacto: (state, action: PayloadAction<ICatContacto>) => {
      var Existe = state.CatContacto.find(function (item) {
        return item.id === action.payload.id
      })
      if (!Existe) state.CatContacto.push(action.payload)
    },
    updateCatContacto: (state, action: PayloadAction<ICatContacto>) => {
      const i = state.CatContacto.findIndex(
        (_element) => _element.id === action.payload.id
      )
      if (i > -1) state.CatContacto[i] = action.payload
      else state.CatContacto.push(action.payload)
    },
    deleteCatContacto: (state, action: PayloadAction<number>) => {
      const newArr = state.CatContacto.filter(
        (data) => data.id != action.payload
      )
      state.CatContacto = newArr
    },
  },
})

export const {
  addCatContacto,
  populateCatContacto,
  updateCatContacto,
  deleteCatContacto,
} = CatContactoSlice.actions
export default CatContactoSlice.reducer
