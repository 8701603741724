import Dropzone from 'react-dropzone'
import MFileManagerDS from '../../../Services/Utils/MFileManager.Service'
import { useEffect, useState } from 'react'
import IFileManager from '../../../Interfaces/Utils/IFileManager'
import { Alert, Button, Col, ListGroup, ListGroupItem, Modal, Row } from 'react-bootstrap'
import { IconContext } from 'react-icons'
import { MsgInformativo } from '../Toast/msgInformativo'
import '../../../css/Cliente.css'
import { BsFillXCircleFill } from 'react-icons/bs'
import { FaFileDownload } from 'react-icons/fa'

export interface IMFileManagerV2Props {
  Tags: number
  Proceso: number
  Usuario: string
  Leyenda: string
  Mode?: number
  doDelete: boolean
}

export default function MFileManagerV2(props: IMFileManagerV2Props) {
  const [ListaArchivos, setListaArchivos] = useState<IFileManager[]>()
  const [NombreArchivo, setNombreArchivo] = useState('')
  const [MsgDialogDelete, setMsgDialogDelete] = useState(false)
  const [IDArchivo, setIDArchivo] = useState(0)
  const [header, setHeader] = useState('')
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  const msgColor = 'primary'

  const appendFiles = (acceptedFiles: any) => {
    var formData = new FormData()
    acceptedFiles.forEach(function (Item: any) {
      formData.append('FileList', Item)
    })
    formData.append('Tags', props.Tags.toString())
    formData.append('Proceso', props.Proceso.toString())
    formData.append('Usuario', props.Usuario)
    MFileManagerDS.Append2(formData)
      .then((response) => {
        setListaArchivos(response.data)
        console.log(response.data)
      })
      .catch((e: Error) => {
        alert('Ocurrio un error: ' + e)
        return
      })
  }

  const getFileList = () => {
    MFileManagerDS.Get(props.Tags, props.Proceso)
      .then((response) => {
        setListaArchivos(response.data)
      })
      .catch((e: Error) => {
        alert('Ocurrio un error: ' + e)
        return
      })
  }

  const confirmDelete = (row: IFileManager) => {
    setIDArchivo(row.id)
    setNombreArchivo(row.nombreArchivo)
    setMsgDialogDelete(true)
  }

  const deleteItem = () => {
    MFileManagerDS.DeleteFile(IDArchivo)
      .then((response) => {
        var arrArchivos = ListaArchivos!.filter(function (el) {
          return el.id !== IDArchivo
        })
        setListaArchivos(arrArchivos)
        setHeader('Informtivo')
        setMsg(response.data.respuesta)
        setMsgDialogDelete(false)
        setShowMsg(false)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error, no se elimino el archivo')
        setMsgDialogDelete(false)
        setShowMsg(false)
        return
      })
    return false
  }

  const getFileContent = (row: IFileManager) => {
    console.log(row)
    MFileManagerDS.getFileContentById(row.id, row.proceso)
      .then((response: any) => {
        if (response.status === 200) {
          if (row.nombreArchivo.toLowerCase().endsWith('.pdf')) {
            const blob = new Blob([response.data], { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blob)
            window.open(url)
          } else if (
            row.nombreArchivo.toLowerCase().endsWith('.png') ||
            row.nombreArchivo.toLowerCase().endsWith('.jpg')
          ) {
            const blob = new Blob([response.data], { type: 'image/png' })
            const url = window.URL.createObjectURL(blob)
            window.open(url)
          } else if (row.nombreArchivo.toLowerCase().endsWith('.docx')) {
            const blob = new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            })
            const url = window.URL.createObjectURL(blob)
            window.open(url)
          } else if (row.nombreArchivo.toLowerCase().endsWith('.xlsx')) {
            const blob = new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
            const url = window.URL.createObjectURL(blob)
            window.open(url)
          } else if (row.nombreArchivo.toLowerCase().endsWith('.xls')) {
            const blob = new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
            const url = window.URL.createObjectURL(blob)
            window.open(url)
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', NombreArchivo ? NombreArchivo : 'Archivo.zip')
            document.body.appendChild(link)
            link.click()
          }
        } else {
          setHeader('Error')
          setMsg('A este concepto no se le ha anexado PDF')
          setShowMsg(true)
          return
        }
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('A este concepto no se le ha anexado PDF')
        setShowMsg(true)
        return
      })
  }

  useEffect(() => {
    getFileList()
  }, [])

  return (
    <div>
      <Row>
        <Col xs={props.Mode === 1 ? 10 : 4}>
          {props.doDelete ? (
            <Dropzone onDrop={appendFiles}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                  <input {...getInputProps()} />
                  {props.Leyenda}
                </div>
              )}
            </Dropzone>
          ) : (
            props.Leyenda
          )}
        </Col>
        <Col xs={props.Mode === 1 ? 2 : 8}>
          <ListGroup>
            {ListaArchivos
              ? ListaArchivos.map((item, index) => {
                  return (
                    <Row>
                      <Col xs={12}>
                        <ListGroupItem style={{ cursor: 'pointer', height: '40px' }}>
                          <Row>
                            <Col xs={props.Mode === 1 ? 6 : 10}>
                              <span
                                style={{ cursor: 'pointer' }}
                                title="De un click para descargar el archivo"
                                onClick={() => {
                                  getFileContent(item)
                                }}
                              >
                                {props.Mode === 1 ? (
                                  <IconContext.Provider value={{ color: 'green', size: '20px' }}>
                                    <FaFileDownload style={{ cursor: 'pointer' }} />
                                  </IconContext.Provider>
                                ) : (
                                  item.nombreArchivo.substring(0, 19) + '..' + item.nombreArchivo.slice(-4)
                                )}
                              </span>
                            </Col>
                            <Col xs={props.Mode === 1 ? 6 : 2} style={{ textAlign: 'right' }}>
                              <span style={{ visibility: props.doDelete ? 'visible' : 'hidden' }}>
                                <IconContext.Provider value={{ color: 'red', size: '20px' }}>
                                  <BsFillXCircleFill
                                    onClick={() => {
                                      confirmDelete(item)
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </IconContext.Provider>
                              </span>
                            </Col>
                          </Row>
                        </ListGroupItem>
                      </Col>
                    </Row>
                  )
                })
              : ''}
          </ListGroup>
        </Col>
      </Row>
      <Modal show={MsgDialogDelete} onHide={() => setMsgDialogDelete(false)} size="lg">
        <Modal.Body>
          <h5>
            <Alert variant="success">
              ¿Esta seguro de eliminar el archivo? <br />
              <br /> {NombreArchivo}
            </Alert>
          </h5>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={1}>
              <Button variant="secondary" onClick={() => setMsgDialogDelete(false)} size="sm">
                Cerrar
              </Button>
            </Col>
            <Col xs={5} style={{ paddingLeft: '550px', paddingRight: '0px' }}>
              &nbsp;
            </Col>
            <Col xs={1}>
              <Button variant="danger" onClick={() => deleteItem()} size="sm">
                Eliminar
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
