import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ICatDepartamento from '../../../Components/Cliente/Interfaces/ICatDepartamento'
const CatDepartamento: ICatDepartamento[] = [
  { id: 0, departamento:'', activo: false },
]
const initialState = { CatDepartamento }

export const CatDepartamentoSlice = createSlice({
  name: 'CatDepartamento',
  initialState: initialState,
  reducers: {
    populateCatDepartamento: (state, action: PayloadAction<ICatDepartamento[]>) => {
      state.CatDepartamento = []
      state.CatDepartamento.push(...action.payload)
    },
    addCatDepartamento: (state, action: PayloadAction<ICatDepartamento>) => {
      var Existe = state.CatDepartamento.find(function (item) {
        return item.id === action.payload.id
      })
      if (!Existe) state.CatDepartamento.push(action.payload)
    },
    updateCatDepartamento: (state, action: PayloadAction<ICatDepartamento>) => {
      const i = state.CatDepartamento.findIndex(
        (_element) => _element.id === action.payload.id
      )
      if (i > -1) state.CatDepartamento[i] = action.payload
      else state.CatDepartamento.push(action.payload)
    },
    deleteCatDepartamento: (state, action: PayloadAction<number>) => {
      const newArr = state.CatDepartamento.filter(
        (data) => data.id != action.payload
      )
      state.CatDepartamento = newArr
    },
  },
})

export const {
  addCatDepartamento,
  populateCatDepartamento,
  updateCatDepartamento,
  deleteCatDepartamento,
} = CatDepartamentoSlice.actions
export default CatDepartamentoSlice.reducer
