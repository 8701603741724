import { useState } from 'react'
import { Button, Card, Col, FloatingLabel, Form, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { IconContext } from 'react-icons'
import {
  BsEraserFill,
  BsFillEraserFill,
  BsFillPencilFill,
  BsXCircleFill,
} from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import CatalogService from '../Services/Catalogos.Services'
import { RootState } from '../../../store/store'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import { AiFillCheckCircle } from 'react-icons/ai'
import ICatEmpleado from '../Interfaces/ICatEmpleado'
import {
  addCatContacto,
  updateCatContacto,
} from '../../../store/features/Catalogos/CatContactosSlice'
import ICatContacto from '../Interfaces/ICatContacto'
import IContacto from '../Interfaces/ICatContacto'
import { FaSave } from 'react-icons/fa'
export interface ICatContactosProps {}

export default function CatContactos(props: ICatContactosProps) {
  const dispatch = useDispatch()
  const mCatContactos = useSelector(
    (state: RootState) => state.CatContactos.CatContacto
  )
  const mCatDepartamentos = useSelector(
    (state: RootState) => state.CatDepartamentos.CatDepartamento
  )
  const [IDCliente, setIDCliente] = useState(0)
  const [IDContacto, setIDContacto] = useState(0)
  const [Nombre, setNombre] = useState('')
  const [Correo, setCorreo] = useState('')
  const [Tel, setTel] = useState('')
  const [Whattsapp, setWhattsapp] = useState(false)
  const [Departamento, setDepartamento] = useState(0)
  const [Activo, setActivo] = useState(false)
  const [Buscar, setBuscar] = useState('')
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')

  const dataContactos = [
    {
      name: 'id',
      width: '5%',
      selector: (row: IContacto) => row.id,
      sortable: true,
    },
    {
      name: 'Activo',
      width: '5%',
      cell: (row: IContacto) => (
        <div>
          <IconContext.Provider
            value={{ color: row.activo ? 'green' : 'red', size: '25px' }}
          >
            {row.activo ? <AiFillCheckCircle /> : <BsXCircleFill />}
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'Nombre',
      width: '30%',
      selector: (row: IContacto) => row.nombre,
    },
    {
      name: 'Correo',
      width: '30%',
      selector: (row: IContacto) => row.correo,
    },
    {
      name: 'Telefono',
      width: '10%',
      selector: (row: IContacto) => row.telefono,
    },
    {
      name: 'Tiene Whattsapp',
      width: '10%',
      selector: (row: IContacto) => (row.whattsapp ? 'Si' : 'No'),
    },
    {
      name: 'Edita',
      width: '5%',
      cell: (row: IContacto) => (
        <div
          style={{ textAlign: 'right', cursor: 'pointer' }}
          onClick={() => {
            setIDContacto(row.id)
            setNombre(row.nombre)
            setTel(row.telefono)
            setCorreo(row.correo)
            setWhattsapp(row.whattsapp)
            setActivo(row.activo)
          }}
        >
          <IconContext.Provider value={{ color: 'blue', size: '25px' }}>
            <BsFillPencilFill />
          </IconContext.Provider>
        </div>
      ),
    },
  ]

  const saveData = () => {
    if (Departamento == 0) {
      setHeader('Error')
      setMsg('Para continuar es necesario que proporcione el departamento')
      setShowMsg(true)
      return
    }
    const data: IContacto = {
      id: IDContacto,
      idCliente: IDCliente,
      nombre: Nombre,
      correo: Correo,
      telefono: Tel,
      departamento: Departamento,
      whattsapp: Whattsapp,
      activo: Activo,
    }
    CatalogService.CatContactosAppend(data)
      .then((response) => {
        IDContacto == 0
          ? dispatch(addCatContacto(data))
          : dispatch(updateCatContacto(data))
        setHeader('Confirmacion')
        setMsg('Los cambios se han guardado exitosamente.')
        setShowMsg(true)
        return
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg('Ocurrio un error: ' + e)
        setShowMsg(true)
        return
      })
  }

  const cleanForm = () => {
    setIDContacto(0)
    setNombre('')
    setTel('')
    setCorreo('')
    setWhattsapp(true)
    setActivo(false)
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Card className='shadow p-3 mb-5 bg-white rounded'>
            <Card.Header as='h5'>Contactos internos</Card.Header>
            <Card.Body>
              <Card.Text>
                <Card>
                  <Card.Body>
                    <Card.Text>
                      <Row>
                        <Col xs={3}>
                          <FloatingLabel
                            controlId='floatingNombre'
                            label='Nombre'
                          >
                            <Form.Control
                              type='text'
                              placeholder='Nombre'
                              value={Nombre}
                              size='sm'
                              onChange={(e) => {
                                setNombre(e.target.value)
                              }}
                            />
                          </FloatingLabel>
                        </Col>
                        <Col xs={2}>
                          <FloatingLabel
                            controlId='floatingNombre'
                            label='Correo'
                          >
                            <Form.Control
                              type='email'
                              placeholder='Correo'
                              value={Correo}
                              size='sm'
                              onChange={(e) => {
                                setCorreo(e.target.value)
                              }}
                            />
                          </FloatingLabel>
                        </Col>
                        <Col xs={2}>
                          <FloatingLabel
                            controlId='floatingTel'
                            label='Telefono'
                          >
                            <Form.Control
                              type='phone'
                              pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                              placeholder='Tel'
                              value={Tel}
                              size='sm'
                              onChange={(e) => {
                                setTel(e.target.value)
                              }}
                            />
                          </FloatingLabel>
                        </Col>
                        <Col xs={1}>
                          <FloatingLabel
                            controlId='floatingSelect'
                            label='¿Whattapp?'
                          >
                            <Form.Select
                              aria-label='Floating tiene whattapp'
                              value={Whattsapp ? 1 : 0}
                              size='sm'
                              onChange={(e) => {
                                setWhattsapp(parseInt(e.target.value) == 1)
                              }}
                            >
                              <option value='0'>No</option>
                              <option value='1'>Si</option>
                            </Form.Select>
                          </FloatingLabel>
                        </Col>
                        <Col xs={2}>
                          <FloatingLabel
                            controlId='floatingSelect'
                            label='Departamento'
                          >
                            <Form.Select
                              aria-label='Floating departamento'
                              value={Departamento}
                              size='sm'
                              onChange={(e) => {
                                setDepartamento(parseInt(e.target.value))
                              }}
                            >
                              <option value='0'>-SELECCIONE-</option>
                              {mCatDepartamentos
                                ? mCatDepartamentos.map((d, index) => {
                                    return (
                                      <option value={d.id}>
                                        {d.departamento}
                                      </option>
                                    )
                                  })
                                : ''}
                            </Form.Select>
                          </FloatingLabel>
                        </Col>
                        <Col xs={1}>
                          <FloatingLabel
                            controlId='floatingSelect'
                            label='¿Activo?'
                          >
                            <Form.Select
                              aria-label='Floating esta activo'
                              value={Activo ? 1 : 0}
                              size='sm'
                              onChange={(e) => {
                                setActivo(parseInt(e.target.value) == 1)
                              }}
                            >
                              <option value='1'>Si</option>
                              <option value='0'>No</option>
                            </Form.Select>
                          </FloatingLabel>
                        </Col>
                        <Col xs={1}>
                          <Row style={{ paddingTop: '15px' }}>
                            <Col xs={6}>
                              <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  cleanForm()
                                }}
                              >
                                <IconContext.Provider
                                  value={{
                                    color: 'orange',
                                    size: '30px',
                                  }}
                                >
                                  <BsFillEraserFill />
                                </IconContext.Provider>
                              </div>
                            </Col>
                            <Col xs={6}>
                              <div
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  saveData()
                                }}
                              >
                                <IconContext.Provider
                                  value={{
                                    color: 'blue',
                                    size: '30px',
                                  }}
                                >
                                  <FaSave />
                                </IconContext.Provider>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row style={{paddingTop:'20px'}}>
                        <Col xs={12}>
                          <Form.Control
                            type='text'
                            id='Buscar'
                            value={Buscar}
                            placeholder='Search...'
                            size='sm'
                            onChange={(e) => {
                              setBuscar(e.target.value)
                            }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ paddingTop: '20px' }}>
                        <Col xs={12}>
                          <DataTable
                            noHeader
                            defaultSortFieldId={'id'}
                            defaultSortAsc={true}
                            pagination
                            dense={true}
                            striped={true}
                            responsive={true}
                            highlightOnHover
                            columns={dataContactos}
                            data={mCatContactos.filter(function (row) {
                              return row.idCliente == 0
                                ? (row.nombre
                                    .toLowerCase()
                                    .includes(Buscar.toLowerCase()) ||
                                    row.correo
                                    .toLowerCase()
                                    .includes(Buscar.toLowerCase()))
                                : null
                            })}
                          />
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Card.Text>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>

      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
