import React, { FC, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { IconContext } from 'react-icons'
import { useDispatch, useSelector } from 'react-redux'
import ICatCliente from '../Interfaces/ICatCliente'
import { RootState } from '../../../store/store'
import { useNavigate } from 'react-router-dom'
import { MsgInformativo } from '../../Utils/Toast/msgInformativo'
import { FcManager } from 'react-icons/fc'
import { BsFillPencilFill, BsXCircleFill } from 'react-icons/bs'
import { AiFillCheckCircle } from 'react-icons/ai'

export interface ICatClientesProps {}

export default function CatClientes(props: ICatClientesProps) {
  let navigate = useNavigate()
  const mCatVendedores = useSelector(
    (state: RootState) => state.CatContactos.CatContacto
  )
  const mCatClientes = useSelector(
    (state: RootState) => state.CatClientes.CatClientes
  )
  const [ID, setID] = useState(0)
  const [Buscar, setBuscar] = useState('')
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [msgColor, setMsgColor] = useState('primary')

  const colData = [
    {
      name: 'id',
      width: '5%',
      selector: (row: ICatCliente) => row.id,
      sortable: true,
    },
    {
      name: 'Estatus cliente',
      width: '8%',
      style: {textAlign: 'center'},
      cell: (row: ICatCliente) => (
        <div>
          <IconContext.Provider
            value={{ color: row.activo ? 'green' : 'red', size: '25px' }}
          >
            {row.activo ? <AiFillCheckCircle /> : <BsXCircleFill />}
          </IconContext.Provider>
        </div>
      ),
    },
    {
      name: 'Estatus proceso',
      width: '8%',
      cell: (row: ICatCliente) => (
        <div  style={{textAlign:'center'}}>{row.estatus === 1 ? 'Datos generales' : ''}</div>
      ),
    },
    {
      name: 'Nombre comercial',
      width: '30%',
      selector: (row: ICatCliente) => row.nombreComercial,
    },
    {
      name: 'Razon Social',
      width: '30%',
      selector: (row: ICatCliente) => row.razonSocial,
    },
    {
      name: 'RFC',
      width: '5%',
      selector: (row: ICatCliente) => row.rfc,
    },
    {
      name: 'Vendedor',
      width: '10%',
      selector: (row: ICatCliente) => getSalesManName(row.vendedor ? row.vendedor : 0),
    },
    {
      name: 'Edita',
      width: '5%',
      cell: (row: ICatCliente) => (
        <div
          style={{ textAlign: 'right', cursor: 'pointer' }}
          onClick={() => {
            navigate('/DatosGenerales/' + row.id)
          }}
        >
          <IconContext.Provider value={{ color: 'blue', size: '25px' }}>
            <BsFillPencilFill />
          </IconContext.Provider>
        </div>
      ),
    },
  ]

  const getSalesManName = (IdEmpleado: number) => {
    let x = mCatVendedores.filter((a)=>{if(a.id==IdEmpleado){return a}});
    return x[0] ? x[0].nombre : 'No asignado'
  }

  return (
    <div>
      <Card>
        <Card.Body>
          <Card>
            <Card.Body>
              <Card.Text>
                <Row style={{ paddingTop: '5px' }}>
                  <Col xs={11}>
                    <Form.Control
                      type='text'
                      id='Buscar'
                      value={Buscar}
                      placeholder='Search...'
                      size='sm'
                      onChange={(e) => {
                        setBuscar(e.target.value)
                      }}
                    />
                  </Col>
                  <Col>
                    <Button
                      variant='primary'
                      onClick={() => {
                        navigate('/DatosGenerales/0')
                      }}
                    >
                      <IconContext.Provider
                        value={{
                          size: '25px',
                        }}
                      >
                        <FcManager />
                      </IconContext.Provider>
                      Nuevo
                    </Button>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '20px' }}>
                  <Col xs={12}>
                    <DataTable
                      noHeader
                      defaultSortFieldId={'id'}
                      defaultSortAsc={true}
                      pagination
                      dense={true}
                      striped={true}
                      responsive={true}
                      highlightOnHover
                      columns={colData}
                      data={mCatClientes.filter(function (row) {
                        return row.id > 0
                          ? row.nombreComercial
                              .toLowerCase()
                              .includes(Buscar.toLowerCase())
                          : null
                      })}
                    />
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>

      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        time={2000}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
